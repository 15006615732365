/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import { GetService, PostService } from "../../../../services/ConstantService";
import { useFormik } from "formik";
import IconUser from "../../../../assets/img/IconUser.png";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import { useParams } from "react-router";
import { ImageURL } from "../../../../Environment/Environment";
import { format, parseISO } from "date-fns";

export default function Viewuser() {
  const [CollaboratorsList, setCollaboratorsList] = useState();
  const [totalPages, setTotalPages] = useState();
  const [submit, setsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [grouplist, setgrouplist] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [DataObj, setDataObj] = useState();

  const [sortGroup, setSortGroup] = useState("");
  const [sortDirection, setSortDirection] = useState(-1);
  const params = useParams();

  const navigate = useNavigate();

  const typeRoute = {
    player: API_URL.PLAYER_DETAILS,
    collaborator: API_URL.COLLABORATORS_DETAILS,
    profile: API_URL.PROFILO_DETAILS,
    referent: API_URL.REFERENTS_DETAILS,
  };

  function getDetails() {
    setLoading(true);
    PostService(typeRoute[params?.type], { id: params?.id }).then(
      (res) => {
        if (res.data.status) {
          console.log(res.data.data);
          setDataObj(res?.data?.data);

          if (res?.data?.data?.groupDetail) {
            const sortedData = res?.data?.data?.groupDetail.sort((a, b) => {
              if (a.playerwallet === 1 && b.playerwallet !== 1) {
                return -1;
              } else if (a.playerwallet !== 1 && b.playerwallet === 1) {
                return 1;
              }
              return 0;
            });

            setSortGroup(sortedData);

            let lastPlayerWalletIndex = -1;
            for (let i = sortedData.length - 1; i >= 0; i--) {
              if (sortedData[i].playerwallet === 1) {
                lastPlayerWalletIndex = i;
                break; // Exit loop once the last index is found
              }
            }
            // console.log(lastPlayerWalletIndex);
            setSortDirection(lastPlayerWalletIndex);
          }
        } else {
          showErrorAlert(res.data?.message);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        showErrorAlert(err.data?.message);
      }
    );
  }

  // const handleSort = (columnName) => {
  //   if (columnName === sortColumn) {
  //     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortColumn(columnName);
  //     setSortDirection("asc");
  //   }
  // };

  // const getHeaderClassName = (columnName) => {
  //   if (columnName === sortColumn) {
  //     return `sorting_${sortDirection}`;
  //   }
  //   return "sorting";
  // };

  // const sortedData = data.slice().sort((a, b) => {
  //   const aValue = a[sortColumn];
  //   const bValue = b[sortColumn];

  //   if (sortDirection === "asc") {
  //     return aValue < bValue ? -1 : 1;
  //   } else {
  //     return aValue > bValue ? -1 : 1;
  //   }
  // });

  useEffect(() => {
    console.log(params);
    getDetails();
  }, [params]);

  const NavigateUser = () => {
    navigate(`/panel/update_${params?.type}/${params?.id}`);
  };

  const DeleteFunc = () => {
    console.log("first");
    // PostService(API_URL.DELETE)

    //     navigate(`/panel/update_${params?.type}/${params?.id}`);
  };

  function getProfileNames(data) {
    return data?.contratii_detail?.map(detail => detail?.profile_detail?.profile_name || "-").join(", ") || "-";
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30 text-secondprimary">
                <span className="text-capitalize">{params?.type}</span> Details
              </h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link
                    to={
                      params?.type === "player"
                        ? "/panel/player"
                        : params?.type === "profile"
                        ? "/panel/profiles"
                        : params?.type === "referent"
                        ? "/panel/referents"
                        : params?.type === "collaborator"
                        ? "/panel/collaborators"
                        : ""
                    }
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    {params?.type}s
                  </Link>
                  <span className="breadcrumb-item active">Details</span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row px-3">
                    {!loading && (
                      <>
                        <div className="col-5 ml-4 rounded py-3 px-4 d-flex flex-column groupPersonalCard">
                          <div>
                            <div className="align-items-center d-flex mb-2">
                              <div
                                style={{ height: "auto", width: "90px" }}
                                className="overflow-hidden rounded-circle mr-3"
                              >
                                <img
                                  src={
                                    params?.type === "referent" ? ` ${DataObj?.referent_details[0]?.image ?  ImageURL + DataObj?.referent_details[0]?.image : IconUser}`  : (DataObj?.image
                                      ? `${ImageURL + DataObj?.image}`
                                      : IconUser)
                                  }
                                  alt="profile"
                                  className="img-md h-100 w-100"
                                />
                              </div>
                              <p className="my-2 h3">{DataObj?.name}</p>
                            </div>{" "}
                            <hr className="groupPersonalCard mb-2" />
                            <div className="d-flex">
                              <div className="col-7 p-0">
                                <label className="text-label">Email</label>
                                <p className="">{DataObj?.email}</p>
                              </div>
                              <div className=" col-5">
                                <label className="text-label">Status </label>
                                <p>
                                  <span
                                    className={`badge ${
                                      DataObj?.status
                                        ? "badge-success"
                                        : "badge-danger"
                                    }`}
                                  >
                                    {DataObj?.status ? "Active" : "Inactive"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {params?.type === "referent" && (
                              <>
                                <div className="d-flex">
                                  {/* <div className="col-7 p-0">
                                    <label className="text-label mb-1">
                                      Player{" "}
                                    </label>
                                    <p className="">
                                      {DataObj?.player_detail?.length > 0
                                        ? DataObj?.player_detail[0]?.players
                                            ?.name
                                        : "NO"}
                                    </p>
                                  </div> */}
                                  <div className="col-7 p-0">
                                    <label className="text-label mb-1">
                                      Profile{" "}
                                    </label>
                                    <p className="">
                                      {getProfileNames(DataObj)}
                                      {/* {DataObj?.contratii_detail?.length > 0
                                        ? DataObj?.contratii_detail[0]
                                            ?.profile_detail?.profile_name
                                        : "NO"} */}
                                    </p>
                                  </div>
                                </div>

                                <div className=" ">
                                  <label className="text-label mb-1">
                                    Collaborators{" "}
                                  </label>
                                  {DataObj?.collaborter?.length > 0 ? (
                                    DataObj?.collaborter?.map(
                                      (data, i, array) => (
                                        <p className="" key={i}>
                                          {data?.mainUser?.name}
                                          {i !== array.length - 1 && (
                                            <span>,</span>
                                          )}
                                        </p>
                                      )
                                    )
                                  ) : (
                                    <p className="">{"NO"}</p>
                                  )}
                                </div>
                              </>
                            )}
                            <div className="">
                              <label className="text-label">Telephone</label>
                              <p className="">
                                {params?.type === "referent"
                                  ? DataObj?.referent_details[0]?.phone
                                  : DataObj?.phone || "NO"}
                              </p>
                            </div>
                            {params?.type === "collaborator" && (
                              <div className="mt-4">
                                <label className="text-label ">
                                  Connected Referent
                                </label>

                                <div className="">
                                  {DataObj?.refrents_detail &&
                                    DataObj?.refrents_detail.map(
                                      (item, index, array) => (
                                        <div className="mb-3" key={index}>
                                          <p className=" ">
                                            {item?.refrents?.name}
                                            {index !== array.length - 1 && (
                                              <span>,</span>
                                            )}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            )}
                            <div className=" ">
                              <label className="text-label mb-1">
                                Creation Date{" "}
                              </label>
                              <p className="">
                                {DataObj?.createdAt &&
                                  format(
                                    parseISO(DataObj?.createdAt),
                                    "dd/MM/yyyy HH:mm"
                                  )}
                              </p>
                            </div>
                          </div>
                          <div className="mt-auto">
                            <div
                              className="col-12 d-flex p-0 mt-5"
                              // style={{ marginTop: "95px" }}
                            >
                              <div className="col-6 p-0 pr-2">
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  data-toggle="modal"
                                  data-target="#DeleteModal"
                                >
                                  <i class="fa fa-trash" aria-hidden="true"></i>{" "}
                                  Delete
                                </button>
                              </div>
                              <div className="col-6 p-0 pl-2">
                                <button
                                  className="btn btn-sm bg-primary text-white w-100 px-2 rounded"
                                  onClick={NavigateUser}
                                >
                                  <i class="fa fa-pen" aria-hidden="true"></i>{" "}
                                  Edit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-6 ml-5 rounded py-3 px-4 groupPersonalCard">
                          <div className="mt-2">
                            <label className="text-label">Description </label>
                            <p className="">{DataObj?.description}</p>
                          </div>

                          <div className="col-12 mt-4 groupPersonalCard rounded p-2">
                            <label className="text-label ">
                              Account Connected
                            </label>
                            <div className="d-flex py-2">
                              <h5>Username :</h5>{" "}
                              <p className="ml-3"> {DataObj?.username}</p>
                            </div>
                            <div className="d-flex py-2">
                              <h5>Password : </h5>{" "}
                              <p className="ml-3">*******</p>
                            </div>
                          </div>

                          {params?.type !== "collaborator" && (
                            <div className="col-12 mt-4 groupPersonalCard rounded p-2">
                              <label className="text-label ">
                                Connected Groups
                              </label>
                              <ul class="list-group">
                                {DataObj?.groupDetail &&
                                  sortGroup.map((item, index) => (
                                    <>
                                      <li
                                        class="list-group-item px-2 py-1 bg-light-list-group"
                                        key={index}
                                      >
                                        <p
                                          style={{ fontSize: "16px" }}
                                          className="d-flex  m-0 py-2"
                                        >
                                          <span className="col-1 mr-2 pr-0">
                                            <i
                                              class="fa fa-bars"
                                              aria-hidden="true"
                                            ></i>
                                          </span>

                                          <span className="col-1 mr-2 pr-0">
                                            {item?.type === "wallet" ? (
                                              <i className="mr-1 fa-solid fa-wallet"></i>
                                            ) : (
                                              <i className="mr-1 fa-solid fa-futbol"></i>
                                            )}
                                          </span>
                                          <span className="col-9 text-left">
                                            {" "}
                                            {item?.name}
                                          </span>
                                        </p>
                                      </li>

                                      {sortDirection === index && (
                                        <div>
                                          <hr />
                                        </div>
                                      )}
                                    </>
                                  ))}
                                {DataObj?.groupDetail?.length < 1 && (
                                  <li class="list-group-item px-2 py-1 bg-light-list-group text-center my-2">
                                    No Connected Groups Found
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete <span className="text-capitalize">{params?.type}</span> !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6>
                {" "}
                Are you sure you want to delete this{" "}
                <span className="text-capitalize">{params?.type}</span> ?{" "}
              </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={DeleteFunc}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
