/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { useParams } from "react-router";
import { ImageURL } from "../../../../Environment/Environment";
import { GetService, PostService } from "../../../../services/ConstantService";
import { showErrorAlert } from "../../../../Helper/SweetAlert";
import IconUser from "../../../../assets/img/IconUser.png";
import { API_URL } from "../../../../services/ApiService";
import { format, parse, parseISO } from "date-fns";
import Pagination from "../../../../Helper/Pagination";
import TablePagination from "@mui/material/TablePagination";
import {
  TooltipComponent2,
  TooltipComponent4,
} from "../../Layout/Comman_Tooltip/Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { getCurrencyListing } from "../../../../Helper/utils";
import { toast } from "react-toastify";

export default function ViewWallet() {
  const [loading, setLoading] = useState(false);
  const [DataObj, setDataObj] = useState(false);

  const [sortColumn, setSortColumn] = useState("");
  const [TransactionList, setTransactionList] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const params = useParams();
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userLimit, setUserLimit] = useState(10);
  const [searchValue, setsearchValue] = useState("");
  const [searchDate, setsearchDate] = useState([]);
  const [searchCurrency, setsearchCurrency] = useState("EUR");
  const [currencylist, setcurrencylist] = useState();
  const [BalanceAmount, setBalanceAmount] = useState(0);
  const [Widgets, setWidgets] = useState();
  const [apicall, setapicall] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const navigate = useNavigate();

  function getDetails() {
    setLoading(true);
    PostService(API_URL.GROUP_DETAIL, { id: params?.id }).then(
      (res) => {
        if (res.data.status) {
          console.log(res.data.data);
          setDataObj(res?.data?.data);
        } else {
          showErrorAlert(res.data?.message);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        showErrorAlert(err.data?.message);
      }
    );
  }

  function getTransactionDetails() {
    setLoading(true);
    GetService(
      `${API_URL.TRANSACTION_LISTING}?id=${
        params?.id
      }&page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&from=${searchDate[0] || ""}&to=${
        searchDate[1] || ""
      }&currency=${searchCurrency}`
    ).then(
      (res) => {
        // console.log(res)
        if (res.data.status) {
          console.log(res.data.data);
          setTransactionList(res?.data?.data?.list);
          setBalanceAmount(res?.data?.data?.balance);
          setWidgets(res?.data?.data?.transactiontotal);
          setTotalPages(res.data?.data?.totalPages);
          setTotal(res?.data?.data?.totalItems);
          setapicall(true);
        } else {
          showErrorAlert(res.data?.message);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        showErrorAlert(err.data?.message);
      }
    );
  }

  function getCurrencylisting() {
    getCurrencyListing()
      .then((res) => {
        setcurrencylist(res);
      })
      .catch((err) => {
        console.error("Error fetching currency list:", err);
        toast.error("Error fetching currency list");
      });
  }

  useEffect(() => {
    getDetails();
    // getTransactionDetails();
    getCurrencylisting();
  }, []);

  useEffect(() => {
    getTransactionDetails();
  }, [currentPage, searchValue, searchDate, searchCurrency, userLimit]);

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = TransactionList?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const NavigateUser = () => {
    navigate(`/panel/update_wallet/${params?.id}`);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    // console.log(dateRange);
    if (apicall) {
      const extractedDates = dateRange.map((dateString) => {
        if (!dateString) {
          return null;
        }
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
      // console.log(extractedDates);
      if (extractedDates[0] && !extractedDates[1]) {
        return;
      } else {
        setsearchDate(extractedDates);
      }
    }
  }, [dateRange]);

  const ExportToCSV = () => {
    const ExportArray = [];
    TransactionList.map((data) => {
      let slipid = data?.slip
        ? encodeURIComponent("slip#" + data?.slip.replace("slip#", ""))
        : "-";

      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd/MM/yyyy HH:mm")
          : "NO",
        Type: data?.transaction_type,
        Tipology: data?.event === "transfer" ? "Transaction" : "Giocata",
        // Items : data?.slip ? data?.slip : "-",
        Items: slipid.replace("%23", "-"),
        Amount: data?.amount,
        Currency: data?.currency,
        Status:
          data?.status === "Pending"
            ? "Pending"
            : data?.status === "Win"
            ? "Win"
            : data?.status === "Lost"
            ? "Lost"
            : data?.status === "Void"
            ? "Void"
            : data?.status === "Win50%"
            ? "Win 50%"
            : data?.status === "Lost50%"
            ? "Lost 50%"
            : data?.status === "Error"
            ? "Error"
            : data?.status === "payed"
            ? "Payed"
            : "-",
        "Profit/Loss": data?.profit_loss
          ? `${data?.profit_loss} ${data?.currency}`
          : "-",
        Balance: `${data?.balance_history} ${data?.currency}` || "-",
        Connected: data?.connected || "-",
        "Payed/Collected":
          data?.role === "refrent"
            ? "Referent"
            : data?.role === "player"
            ? "Player"
            : "-",
        Tag: data?.tag_detail?.name || "-",
        Note: data?.note || "-",
      });
    });

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", `${DataObj?.name}.csv`);

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  // const GetBalanceAmount=()=>{
  //   const data = DataObj?.groupcurrency.filter((data)=>{
  //    console.log(data)
  //      return searchCurrency === data?.currency
  //    })
  //    console.log(data)
  //    return data[0]?.amount || 0
  //  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-7 d-flex">
              <h3 className="d-flex align-items-center text-secondprimary">
                <span
                  className={`mr-1 ${
                    DataObj?.tag === "Passivity"
                      ? "badge-dot-rd"
                      : DataObj?.tag === "Activity"
                      ? "badge-dot-gr"
                      : ""
                  }`}
                ></span>{" "}
                {`${DataObj?.name}`} Details
              </h3>
              <span>
                <ol className="breadcrumb mb-0">
                  <Link to="/panel/dashboard" className="breadcrumb-item">
                    Home
                  </Link>
                  <Link to="/panel/wallets" className="breadcrumb-item">
                    Wallets
                  </Link>
                  <span className="breadcrumb-item active">
                    {params?.type === "personal_data"
                      ? "Personal Data"
                      : params?.type === "transaction"
                      ? "Transaction"
                      : "Performance"}
                  </span>
                </ol>
              </span>
            </div>
            <div className="col-5">
              <div className="float-right group_breadcrumb">
                <Link
                  className={`breadcrumb-item ${
                    params?.type === "personal_data" ? "border-bottom" : ""
                  }`}
                  to={`/panel/view_wallet/${params?.id}/personal_data`}
                >
                  Personal Data{" "}
                </Link>{" "}
                <span className="mx-2">|</span>
                <Link className="breadcrumb-item">Performance </Link>{" "}
                <span className="mx-2">|</span>
                <Link
                  className={`breadcrumb-item ${
                    params?.type === "transaction" ? "border-bottom" : ""
                  }`}
                  to={`/panel/view_wallet/${params?.id}/transaction`}
                >
                  Transaction
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {
                    <>
                      {params?.type === "personal_data" && (
                        <div className="row px-3">
                          <div className="col-5 ml-4 rounded py-3 groupPersonalCard">
                            <div className="align-items-center d-flex mb-2">
                              <div
                                style={{ height: "90px", width: "90px" }}
                                className="overflow-hidden rounded-circle mr-3"
                              >
                                <img
                                  src={
                                    DataObj?.image
                                      ? `${ImageURL + DataObj?.image}`
                                      : IconUser
                                  }
                                  alt="profile"
                                  className="img-md h-100 w-100"
                                />
                              </div>
                              <p className="my-2 h3">{DataObj?.name}</p>
                              {DataObj?.playerwallet === 1 && (
                                <p
                                  className="badge badge-primary"
                                  style={{
                                    position: "absolute",
                                    top: "-3px",
                                    left: "-2px",
                                  }}
                                >
                                  PW
                                </p>
                              )}
                            </div>{" "}
                            {/* <h4 className="fs-30 mt-3 text-center">
                              {DataObj?.name}
                            </h4>{" "} */}
                            <hr className="groupPersonalCard mb-2" />
                            <div className="d-flex">
                              <div className="col-8 pl-0">
                                <div className=" ">
                                  <label className="text-label mb-1">
                                    Category{" "}
                                  </label>
                                  <p className=" ">
                                    {DataObj.category === "Bank" ? (
                                      <i
                                        class="fa fa-university fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    ) : DataObj?.category === "Crypto" ? (
                                      <i class="fa-brands fa-bitcoin fa-lg"></i>
                                    ) : DataObj?.category === "Cash" ? (
                                      <i class="fa-solid fa-money-bill fa-lg"></i>
                                    ) : DataObj?.category === "Credit/Debit" ? (
                                      <i
                                        class="fa fa-credit-card fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      ""
                                    )}{" "}
                                    &nbsp; {DataObj?.category}
                                  </p>
                                </div>
                              </div>
                              <div className="col-4">
                                <label className="text-label">Status </label>
                                <p>
                                  <span
                                    className={`badge ${
                                      DataObj?.status
                                        ? "badge-success"
                                        : "badge-danger"
                                    }`}
                                  >
                                    {DataObj?.status ? "Active" : "Inactive"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="col-8 pl-0">
                                <label className="text-label mb-1">
                                  Player{" "}
                                </label>
                                <p className=" ">
                                  {DataObj?.player?.name || "N/O"}
                                </p>
                              </div>
                              <div className="col-4">
                                <label className="text-label">Tag</label>
                                <p>
                                  <span
                                    className={`badge ${
                                      DataObj?.tag === "Activity"
                                        ? "bg-inverse-success text-black"
                                        : DataObj?.tag === "Passivity"
                                        ? "bg-inverse-danger text-black"
                                        : ""
                                    }`}
                                  >
                                    {DataObj?.tag}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className=" ">
                              <label className="text-label mb-1">
                                Referent{" "}
                              </label>
                              <p className=" ">
                                {DataObj?.referent?.name || "NO"}
                              </p>
                            </div>
                            <div className=" ">
                              <label className="text-label mb-1">
                                Collaborators{" "}
                              </label>
                              {DataObj?.Collaborators?.length ? (
                                DataObj?.Collaborators?.map(
                                  (data, i, array) => (
                                    <p className=" " key={i}>
                                      {data?.mainUser?.name}
                                      {i !== array.length - 1 && <span>,</span>}
                                    </p>
                                  )
                                )
                              ) : (
                                <p className=" ">NO</p>
                              )}
                            </div>
                            <div className=" ">
                              <label className="text-label mb-1">
                                Creation Date{" "}
                              </label>
                              <p className=" ">
                                {DataObj?.createdAt &&
                                  format(
                                    parseISO(DataObj?.createdAt),
                                    "dd/MM/yyyy HH:mm"
                                  )}
                              </p>
                            </div>
                            <div className="col-12 d-flex p-0 mt-5">
                              <div className="col-6 p-0 pr-2">
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  data-toggle="modal"
                                  data-target="#DeleteModal"
                                >
                                  <i class="fa fa-trash" aria-hidden="true"></i>{" "}
                                  Delete
                                </button>
                              </div>
                              <div className="col-6 p-0 pl-2">
                                <button
                                  className="btn btn-sm bg-primary text-white w-100 px-2 rounded"
                                  onClick={NavigateUser}
                                >
                                  <i class="fa fa-pen" aria-hidden="true"></i>{" "}
                                  Edit
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col-6 ml-5 rounded py-3 px-4 groupPersonalCard">
                            <div className="mt-2">
                              <label className="text-label">Description </label>
                              <p className=" ">{DataObj?.description}</p>
                            </div>

                            {/* <div className="mt-3">
                              <label className="text-label ">
                                Total Amount
                              </label>
                              <h4 className="font-weight-bold"> 
                                {Number(DataObj?.grand_total || 0).toFixed(3)} €
                              </h4>
                            </div> */}

                            <div className="row mt-4">
                              <div className="col-5 p-2 groupPersonalCard rounded mx-3">
                                <label className="text-label">
                                  Initial Balance
                                </label>
                                <table className="table table-hover p-2 ">
                                  <tbody>
                                    {DataObj?.initialBalance?.length ? (
                                      DataObj?.initialBalance.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>
                                              <p className="d-flex justify-content-around m-0 py-2">
                                                <span>
                                                  <i
                                                    class="fa fa-bars"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <span className="font-weight-medium">
                                                  {row?.amount?.toLocaleString()}
                                                </span>
                                                <span className="mr-3 text font-weight-medium">
                                                  {row.currency}
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <p className="text-center pt-2">
                                        No Balance Found
                                      </p>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="col-5 p-2 groupPersonalCard rounded mx-3">
                                <label className="text-label">
                                  Current Balance
                                </label>
                                <table className="table table-hover p-2 ">
                                  <tbody>
                                    {DataObj?.groupcurrency?.length ? (
                                      DataObj?.groupcurrency.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>
                                              <p className="d-flex justify-content-around m-0 py-2">
                                                <span>
                                                  <i
                                                    class="fa fa-bars"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <span className="font-weight-medium">
                                                  {row?.amount?.toLocaleString()}
                                                </span>
                                                <span className="mr-3 text font-weight-medium">
                                                  {row.currency}
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <p className="text-center pt-2">
                                        No Balance Found
                                      </p>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="mt-4">
                              <div className="col-12 groupPersonalCard rounded p-2">
                                <label className="text-label ">
                                  Connected Groups
                                </label>

                                <table className="table table-hover p-2 ">
                                  <tbody>
                                    {DataObj?.group_detail?.length > 0 &&
                                      DataObj?.group_detail.map(
                                        (item, index) => (
                                          <tr className="mb-3" key={index}>
                                            <td>
                                              <p
                                                style={{ fontSize: "16px" }}
                                                className="d-flex  m-0 py-2"
                                              >
                                                <span>
                                                  <i
                                                    class="fa fa-bars"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>

                                                <span className="col-2">
                                                  {item?.satellite_group
                                                    ?.type === "wallet" ? (
                                                    <i className="mr-1 fa-solid fa-wallet"></i>
                                                  ) : (
                                                    <i className="mr-1 fa-solid fa-futbol"></i>
                                                  )}
                                                </span>
                                                <span className="col-8 text-left">
                                                  {" "}
                                                  {item?.satellite_group?.name}
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      )}

                                    {DataObj?.groups_detail?.length > 0 &&
                                      DataObj?.groups_detail.map(
                                        (item, index) => (
                                          <tr className="mb-3" key={index}>
                                            <td>
                                              <p
                                                style={{ fontSize: "16px" }}
                                                className="d-flex  m-0 py-2"
                                              >
                                                <span>
                                                  <i
                                                    class="fa fa-bars"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>

                                                <span className="col-2">
                                                  {item?.group_main_detail
                                                    ?.type === "wallet" ? (
                                                    <i className="mr-1 fa-solid fa-wallet"></i>
                                                  ) : (
                                                    <i className="mr-1 fa-solid fa-futbol"></i>
                                                  )}
                                                </span>
                                                <span className="col-8 text-left">
                                                  {" "}
                                                  {
                                                    item?.group_main_detail
                                                      ?.name
                                                  }
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      )}

                                    {DataObj?.group_detail?.length === 0 &&
                                      DataObj?.groups_detail?.length === 0 && (
                                        <p className="text-center pt-2">
                                          No Group Found
                                        </p>
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {params?.type === "transaction" && (
                        <>
                          <div className="row">
                            <div className="col-md-4 ">
                              <label
                                htmlFor="search-input"
                                className="search-label"
                              >
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>
                              </label>
                              <input
                                type="search"
                                name=""
                                id="search-input"
                                className="form-control pl-5 form-control-sm"
                                placeholder="Search"
                                onChange={(e) => setsearchValue(e.target.value)}
                              />
                            </div>
                            <div className="col-md-3 pr-0">
                              <DatePicker
                                className="form-control form-control-sm"
                                placeholderText="Select Date range"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  console.log(update);
                                  setDateRange(update);
                                }}
                                isClearable={true}
                              />
                            </div>

                            <div className="col-md-2 pl-0">
                              <select
                                className="form-control form-control-sm"
                                onChange={(e) =>
                                  setsearchCurrency(e.target.value)
                                }
                                value={searchCurrency}
                              >
                                <option value={""}>Select Currency</option>
                                {currencylist?.map((item, i) => (
                                  <option key={i} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-1"></div>

                            <div className="col-md-2">
                              <button
                                className="badge badge-outline-primary fa-1x rounded float-right"
                                onClick={ExportToCSV}
                              >
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>{" "}
                                Export
                              </button>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-md-4">
                              <div className="card groupPersonalCard groupPersonalCard_1 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="color fs-16 mb-0">
                                      Balance
                                    </h6>
                                    <i className="fa-solid fa-chart-simple fs-16"></i>
                                  </div>
                                  <h2 className="my-2 color align-items-center d-flex ">
                                    {searchCurrency && BalanceAmount
                                      ? BalanceAmount?.toLocaleString()
                                      : 0}
                                    <span className="fs-16 ml-1">
                                      {searchCurrency}
                                    </span>
                                    {/* €{" "}
                                    {Number(DataObj?.grand_total || 0).toFixed(
                                      2
                                    )} */}
                                  </h2>
                                  {/* <p className="mb-0  font-weight-bold">
                                    <span className="text-success">5.20%</span>{" "}
                                    &nbsp; Last 7 days
                                  </p> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="card d-flex groupPersonalCard color groupPersonalCard_2 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16">
                                      Expenses{" "}
                                      <small> (Tax, Free roll, fixed) </small>
                                    </h6>
                                    <i class="fa-solid fa-money-bills fs-16"></i>
                                  </div>

                                  <h2 className="my-2 color">
                                    0.00{" "}
                                    <span className="fs-16">
                                      {searchCurrency}
                                    </span>
                                  </h2>
                                  {/* <p className="mb-0  font-weight-bold">
                                    <span className="text-success">5.20%</span>{" "}
                                    &nbsp; Last 7 days
                                  </p> */}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="card groupPersonalCard groupPersonalCard_3 rounded-lg">
                                <div className="card-body py-2">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-0 color fs-16">
                                      Profit / loss
                                    </h6>
                                    <i class="fa-solid fa-sack-xmark fs-16"></i>
                                  </div>

                                  <h2 className="my-2 color">
                                    <span
                                      className={`${
                                        searchCurrency &&
                                        Widgets?.totalprofitloss > 0
                                          ? "text-success"
                                          : searchCurrency &&
                                            Widgets?.totalprofitloss < 0
                                          ? "text-danger"
                                          : searchCurrency &&
                                            Widgets?.totalprofitloss === 0
                                          ? "text-secondary"
                                          : ""
                                      }`}
                                    >
                                      {searchCurrency &&
                                      Widgets?.totalprofitloss
                                        ? Widgets?.totalprofitloss?.toLocaleString()
                                        : 0}
                                    </span>{" "}
                                    <span className="fs-16">
                                      {searchCurrency}
                                    </span>
                                  </h2>
                                  {/* <p className="mb-0  font-weight-bold">
                                    <span className="text-success">5.20%</span>{" "}
                                    &nbsp; Last 7 days
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          {!loading && (
                            <div className="row mt-4">
                              <div className="col-12 grid-margin stretch-card">
                                <div className="card groupPersonalCard pb-5 px-2 pt-1 rounded">
                                  <div className="card-body px-1 py-0">
                                    {/* <h4 className="card-title mt-3 my-3 mb-1 ml-2">
                                    Group Transaction Table
                                  </h4> */}
                                    <div className="table-responsive pb-2">
                                      <table
                                        id="order-listing"
                                        className="table dataTable no-footer   table-hover"
                                        role="grid"
                                      >
                                        <thead className="static-header ">
                                          <tr role="row">
                                            <th className="px-2">S.No</th>
                                            {/* <th
                                            className={getHeaderClassName("id")}
                                            onClick={() => handleSort("id")}
                                          >
                                            ID
                                          </th> */}
                                            <th
                                              className={getHeaderClassName(
                                                "createdAt"
                                              )}
                                              onClick={() =>
                                                handleSort("createdAt")
                                              }
                                            >
                                              Date
                                            </th>
                                            <th className="px-2">Dir</th>

                                            <th className="px-2">Typology</th>
                                            <th className="px-2">Items</th>
                                            <th className="px-2">Category</th>
                                            <th
                                              className="px-2"
                                              // className={getHeaderClassName(
                                              //   "amount"
                                              // )}
                                              // onClick={() => handleSort("amount")}
                                            >
                                              Amount
                                            </th>
                                            <th
                                              className={getHeaderClassName(
                                                "status"
                                              )}
                                              onClick={() =>
                                                handleSort("status")
                                              }
                                            >
                                              Status
                                            </th>
                                            <th className="px-2">
                                              Profit/Loss
                                            </th>
                                            <th className="px-2">Connected</th>
                                            <th className="px-2">Balance</th>
                                            <th className="px-2">
                                              Payed/Collected
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {TransactionList &&
                                            sortedData?.map((item, i) => (
                                              <tr key={i}>
                                                <td>
                                                  {i +
                                                    currentPage * userLimit +
                                                    1}
                                                </td>
                                                <td>
                                                  {item?.createdAt
                                                    ? format(
                                                        parseISO(
                                                          item?.createdAt
                                                        ),
                                                        "dd/MM/yyyy HH:mm"
                                                      )
                                                    : "N/A"}
                                                </td>

                                                {/* <td>
                                                {item?.status === "Pending" ? (
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↘
                                                  </span>
                                                ) : item?.status === "Win" ? (
                                                  <span
                                                    style={{
                                                      color: "green",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↗
                                                  </span>
                                                ) : item?.status === "Lost" ? (
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↘
                                                  </span>
                                                ) : item?.status ===
                                                  "Lost50%" ? (
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↘
                                                  </span>
                                                ) : item?.status === "Void" ? (
                                                  <span
                                                    style={{
                                                      color: "primary",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ...
                                                  </span>
                                                ) : item?.status ===
                                                  "Win50%" ? (
                                                  <span
                                                    style={{
                                                      color: "green",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↗
                                                  </span>
                                                ) : item?.status === "Error" ? (
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↘
                                                  </span>
                                                ) : item?.status === "payed" &&
                                                  item?.transaction_type ===
                                                    "outcome" ? (
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↘
                                                  </span>
                                                ) : item?.status === "payed" &&
                                                  item?.transaction_type ===
                                                    "income" ? (
                                                  <span
                                                    style={{
                                                      color: "green",
                                                      fontSize: "large",
                                                    }}
                                                  >
                                                    {" "}
                                                    ↗
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </td> */}

                                                <td>
                                                  {item?.transaction_type ===
                                                  "outcome" ? (
                                                    <span
                                                      style={{
                                                        color: "red",
                                                        fontSize: "large",
                                                      }}
                                                    >
                                                      {" "}
                                                      ↘
                                                    </span>
                                                  ) : item?.transaction_type ===
                                                    "income" ? (
                                                    <span
                                                      style={{
                                                        color: "green",
                                                        fontSize: "large",
                                                      }}
                                                    >
                                                      {" "}
                                                      ↗
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>

                                                <td className="text-capitalize">
                                                  {item?.event ===
                                                  "transfer" ? (
                                                    <span className="badge badge-info ">
                                                      {"Transaction"}
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-primary">
                                                      {"Giocata"}
                                                    </span>
                                                  )}
                                                </td>

                                                <td>
                                                  {item ? (
                                                    <TooltipComponent4
                                                      data={item}
                                                    ></TooltipComponent4>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.category ===
                                                  "transfer" ? (
                                                    <span className="badge bg-inverse-warning text-black">
                                                      Transfer
                                                    </span>
                                                  ) : item?.category ===
                                                    "parlay" ? (
                                                    <span className="badge bg-inverse-info">
                                                      Parlay
                                                    </span>
                                                  ) : item?.category ===
                                                    "Fixed" ? (
                                                    <span className="badge bg-inverse-primary">
                                                      Fixed
                                                    </span>
                                                  ) : item?.category ===
                                                    "single" ? (
                                                    <span className="badge bg-inverse-primary">
                                                      Single
                                                    </span>
                                                  ) : item?.category ===
                                                    "income" ? (
                                                    <span className="badge bg-inverse-success">
                                                      Income
                                                    </span>
                                                  ) : item?.category ===
                                                    "outcome" ? (
                                                    <span className="badge bg-inverse-danger">
                                                      Outcome
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.amount?.toLocaleString()}{" "}
                                                  {item?.currency}
                                                </td>

                                                <td>
                                                  <div className="btn-group">
                                                    {item?.status ===
                                                    "Pending" ? (
                                                      <span className="btn btn-sm btn-warning align-items-center d-flex px-2 p-1">
                                                        Pending
                                                      </span>
                                                    ) : item?.status ===
                                                      "Win" ? (
                                                      <span className="btn btn-sm btn-success align-items-center d-flex px-2 p-1">
                                                        Win
                                                      </span>
                                                    ) : item?.status ===
                                                      "Lost" ? (
                                                      <span className="btn btn-sm btn-danger align-items-center d-flex px-2 p-1">
                                                        Lost
                                                      </span>
                                                    ) : item?.status ===
                                                      "Void" ? (
                                                      <span className="btn btn-sm btn-info align-items-center d-flex px-2 p-1">
                                                        Void
                                                      </span>
                                                    ) : item?.status ===
                                                      "Win50%" ? (
                                                      <span className="btn btn-sm btn-secondary align-items-center d-flex px-2 p-1">
                                                        Win 50%
                                                      </span>
                                                    ) : item?.status ===
                                                      "Lost50%" ? (
                                                      <span className="btn btn-sm btn-secondary align-items-center d-flex px-2 p-1">
                                                        Lost 50%
                                                      </span>
                                                    ) : item?.status ===
                                                      "Error" ? (
                                                      <span className="btn btn-sm btn-danger align-items-center d-flex px-2 p-1">
                                                        Error
                                                      </span>
                                                    ) : item?.status ===
                                                      "payed" ? (
                                                      <span className="btn btn-sm btn-success align-items-center d-flex px-2 p-1">
                                                        Payed
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  {item?.profit_loss !==
                                                  null ? (
                                                    <>
                                                      {" "}
                                                      <span
                                                        className={`font-weight-500 ${
                                                          item?.profit_loss > 0
                                                            ? "text-success"
                                                            : item?.profit_loss <
                                                              0
                                                            ? "text-danger"
                                                            : item?.profit_loss ===
                                                              0
                                                            ? "text-secondary"
                                                            : ""
                                                        }`}
                                                      >
                                                        {item?.profit_loss?.toLocaleString()}
                                                      </span>{" "}
                                                      <span>
                                                        {item?.currency}{" "}
                                                      </span>{" "}
                                                    </>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.connected ? (
                                                    <>
                                                      <span
                                                        className={`mr-1 ${
                                                          item?.connectedTag ===
                                                          "Passivity"
                                                            ? "badge-dot-rd"
                                                            : item?.connectedTag ===
                                                              "Activity"
                                                            ? "badge-dot-gr"
                                                            : ""
                                                        }`}
                                                      ></span>{" "}
                                                      {item?.connected}
                                                    </>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </td>
                                                <td>
                                                  {item?.balance_history?.toLocaleString()}{" "}
                                                  {item?.currency}
                                                </td>
                                                <td>
                                                  {item?.role === "refrent" ? (
                                                    <span className="badge badge-info">
                                                      Referent
                                                    </span>
                                                  ) : item?.role ===
                                                    "player" ? (
                                                    <span className="badge badge-primary">
                                                      Player
                                                    </span>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                          {TransactionList &&
                                            TransactionList?.length === 0 && (
                                              <tr>
                                                <td
                                                  colSpan="12"
                                                  className="text-center"
                                                >
                                                  <h6 className="p-3">
                                                    {" "}
                                                    No Data Found{" "}
                                                  </h6>
                                                </td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="mt-4 ml-auto">
                                      <TablePagination
                                        component="div"
                                        count={total}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                          handleChangeRowsPerPage
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  }
                  {loading ? (
                    <div className="LoaderSpinner">
                      <MoonLoader
                        color={"#003bff"}
                        loading={loading}
                        speedMultiplier={0.6}
                        size={50}
                      />
                      <div>Loading... Please wait.</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
