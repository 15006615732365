/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../../services/ConstantService";
import { useFormik } from "formik";
import IconUser from "../../../../assets/img/IconUser.png";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import GroupSearch from "../../Layout/SearchGroup/SearchGroup";
import { useRef } from "react";
import CollaboratorsSearch from "./Collaborators_Search";
import TooltipComponent from "../../Layout/Comman_Tooltip/Tooltip";
import { ImageURL } from "../../../../Environment/Environment";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { format, parseISO } from "date-fns";
import TablePagination from "@mui/material/TablePagination";

export default function Referents() {
  const [ReferentsList, setReferentsList] = useState();
  const [totalPages, setTotalPages] = useState();
  const [submit, setsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const [profilolist, setprofilolist] = useState();
  const [playerlist, setplayerlist] = useState();
  const [selectedcollaboratorsOptions, setselectedcollaboratorsOptions] =
    useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [searchValue, setsearchValue] = useState("");
  const [SearchPlayer, setSearchPlayer] = useState("");
  const [SearchProfile, setSearchProfile] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const resetStateRef = useRef(false);
  const inputdata = useRef("");
  const inputdataforcollabotors = useRef("");
  const component = useRef("referents");

  const handleResetButtonClick = () => {
    resetStateRef.current = !resetStateRef.current;
  };
  const handleSelectedOptionsChange = (options) => {
    setSelectedOptions(options);
  };
  const handleSelectedCollaboratorsOptionsChange = (options) => {
    setselectedcollaboratorsOptions(options);
  };
  const handleReset = () => {
    formik.resetForm();
    inputdata.current = "";
    inputdataforcollabotors.current = "";
    setsubmit(false);
    handleResetButtonClick();
    setSelectedOptions("");
    setDisabledField(false);
    setselectedFile("");
    setSelectedImage("");
  };

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_STATUS_REFERENCE, data).then(
      (res) => {
        if (res?.data?.status) {
          showSuccessAlert(res?.data?.message, getReferentslisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };

  function getReferentslisting() {
    setLoading(true);
    GetService(
      `${
        API_URL.GET_REFERENCE
      }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&player_id=${SearchPlayer}&profile_id=${SearchProfile}`
    ).then((res) => {
      setLoading(false);
      if (res?.status) {
        setReferentsList(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
      }
    });
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
      profile_id: "",
      description: "",
      status: 1,
    },
    validate: (values) => {
      const errors = {};

      // Validate 'name' field
      if (!values.name) {
        errors.name = "Name is required";
      }

      // Validate 'lastname' field
      if (!values.lastname) {
        errors.lastname = "Lastname is required";
      }

      // Validate 'email' field
      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      // Validate 'phone' field
      if (!values.phone) {
        errors.phone = "Phone is required";
      } else if (CheckTelLength(values.phone)) {
        errors.phone = "Phone length";
      }

      if (!selectedState && !values.password) {
        errors.password = "Password is required";
      }

      if (!selectedState) {
        if (!values.confirm_password) {
          errors.confirm_password = "Confirm Password is required";
        } else if (values.confirm_password !== values.password) {
          errors.confirm_password = "Password mismatch";
        }
      }

      if (!values.description) {
        errors.description = "description is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      if (formik.isValid) {
        const route = selectedState
          ? API_URL.UPDATE_REFERENCE
          : API_URL.CREATE_REFERENCE;

        const selectedGroupId = [];
        const selectedcollaboratorsId = [];
        if (selectedOptions?.length > 0) {
          for (let i of selectedOptions) {
            selectedGroupId.push(i?.id);
          }
          values.group = selectedGroupId;
        }
        //  for collaborators
        if (selectedcollaboratorsOptions?.length > 0) {
          for (let i of selectedcollaboratorsOptions) {
            selectedcollaboratorsId.push(i?.id);
          }
          values.collabority = selectedcollaboratorsId;
        }

        const Formdata = new FormData();
        if (selectedState) {
          Formdata.append("id", selectedState?.id);
        }
        if (!selectedState) {
          Formdata.append("password", values?.password);
          Formdata.append("confirm_password", values?.confirm_password);
        }
        if (values?.lastname && values?.name) {
          Formdata.append("user_name", values?.name + " " + values?.lastname);
        }
        Formdata.append("description", values?.description);
        Formdata.append("email", values?.email);
        Formdata.append("lastname", values?.lastname);
        Formdata.append("name", values?.name);
        Formdata.append("phone", values?.phone);
        Formdata.append("contratii", `[${values?.profile_id}]`);
        Formdata.append("profile_image", selectedFile);
        Formdata.append("group", JSON.stringify(selectedGroupId));
        Formdata.append("collabority", JSON.stringify(selectedcollaboratorsId));
        Formdata.append("status", 1);

        PostFormData(route, Formdata).then(
          (res) => {
            if (res?.data.status) {
              document.getElementById("closeModal").click();
              handleReset();
              inputdata.current = "";
              inputdataforcollabotors.current = "";
              showSuccessAlert(res?.data?.message, getReferentslisting);
            } else {
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            showErrorAlert(err.data?.message);
          }
        );
      }
    },
  });

  const [DisabledField, setDisabledField] = useState(false);

  function Setdata(values) {
    setDisabledField(true);
    setSelectedImage(ImageURL + values?.profile_image);
    setselectedFile(values?.profile_image);
    formik.setValues({
      name: values?.name,
      lastname: values?.lastname,
      phone: values?.phone,
      email: values?.email,
      status: values?.status,
      description: values?.description,
      profile_id: values?.contratii_detail[0]?.attach_id,
    });
    inputdata.current = values?.user_detail;
    inputdataforcollabotors.current = values?.collobority_detail;
    setselectedState(values);
    component.current = "referents";
  }

  useEffect(() => {
    getReferentslisting();
    // getProfilolisting();
  }, [currentPage, searchValue, SearchProfile, SearchPlayer, userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = ReferentsList?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const [selectedFile, setselectedFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));

    // console.log("Selected file:", e.target.files[0]);
  };

  const handlePhoneChange = (value) => {
    console.log(value);
    if (value && value.trim() !== "") {
      formik.setFieldValue("phone", value);
    }
  };

  function CheckTelLength(value) {
    // console.log(value)
    const phoneNumberObject = parsePhoneNumber(value);
    const nsn = phoneNumberObject ? phoneNumberObject.nationalNumber : "";
    const nsnLength = nsn.length;
    // console.log(nsn)
    // console.log(nsnLength)

    if (nsnLength >= 8 && nsnLength <= 15) {
      return false;
    } else {
      return true;
    }
  }

  function getProfileNames(data) {
    return data?.contratii_detail?.map(detail => detail?.profile_detail?.profile_name || "-").join(", ") || "-";
  }
  const ExportToCSV = () => {
    const ExportArray = [];
    ReferentsList.map((data) =>
      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd/MM/yyyy HH:mm")
          : "NO",
        Status: data?.status === 1 ? "Active" : "Inactive",

        Name: data?.name,
        Username: data?.username,
        Email: data?.email,
        Telephone: data?.phone,
        Profile: getProfileNames(data),
        Player: data?.player_detail[0]?.players?.name || "-",
        Collaborator: data?.collaborter
          .map((entry, i) => `(${i + 1}) ${entry?.mainUser?.name}`)
          .join(" "),
        Connected: data?.groupDetail
          .map((entry, i) => `(${i + 1}) ${entry?.name}`)
          .join(" "),
        Description: data?.description,
      })
    );

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Referents_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  useEffect(() => {
    getplayerlisting();
    getprofilelisting();
  }, []);

  function getplayerlisting() {
    GetService(API_URL.PLAYERS_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setplayerlist(res?.data?.data);
      }
    });
  }

  function getprofilelisting() {
    GetService(API_URL.PROFILES_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setprofilolist(res?.data?.data);
      }
    });
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Referents</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">Referents</Link>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <NavLink to={"/panel/new_referent"}>
                  <div
                    className="btn  bg-secondprimary rounded-sm text-white"
                    style={{ minWidth: "180px" }}
                  >
                    <i className="fa-solid fa-plus mr-1"></i>
                    Add Referent
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mb-4">
                    <div className="col-md-4 pl-2">
                      <label htmlFor="search-input" className="search-label">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </label>
                      <input
                        type="search"
                        id="search-input"
                        className="form-control pl-5 form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setsearchValue(e.target.value)}
                      />
                    </div>

                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchProfile(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Profile</option>
                        {profilolist &&
                          profilolist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.profile_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchPlayer(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Player</option>
                        {playerlist &&
                          playerlist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-md-2 ml-auto">
                      <button
                        className="badge badge-outline-primary fa-1x rounded float-right"
                        onClick={ExportToCSV}
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="row px-3">
                    <div class="table-responsive pb-2">
                      {!loading && (
                        <table class="table dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              {/* <th
                                className={getHeaderClassName("id")}
                                onClick={() => handleSort("id")}
                              >
                                ID
                              </th> */}
                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>
                              <th
                                className={getHeaderClassName("name")}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </th>
                              {/* <th
                                className={getHeaderClassName("email")}
                                onClick={() => handleSort("email")}
                              >
                                Email
                              </th> */}
                              <th>Profile</th>
                              {/* <th
                              // className={getHeaderClassName(
                              //   "contratii_detail"
                              // )}
                              // onClick={() => handleSort("contratii_detail")}
                              >
                                Player
                              </th> */}
                              <th
                              // className={getHeaderClassName(
                              //   "collobority_detail"
                              // )}
                              // onClick={() => handleSort("collobority_detail")}
                              >
                                Collaborator
                              </th>

                              <th
                              // className={getHeaderClassName("user_detail")}
                              // onClick={() => handleSort("user_detail")}
                              >
                                Connected
                              </th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ReferentsList &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td>{i + currentPage * userLimit + 1}</td>
                                  {/* <td>{item?.id}</td> */}
                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item &&
                                      item?.referent_details &&
                                      item?.referent_details[0]?.status
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/panel/view_user/${item?.id}/referent`}
                                    >
                                      {item &&
                                        item?.referent_details &&
                                        item?.referent_details[0]?.name}
                                    </Link>
                                  </td>
                                  {/* <td>{item?.email ? item?.email : "NO"}</td> */}
                                  <td>
                                    <span>
                                    {getProfileNames(item)}
                                    </span>
                                    {/* <Link
                                      to={
                                        item?.contratii_detail[0]
                                          ?.profile_detail?.profile_name &&
                                        `/panel/view_user/${item?.contratii_detail[0]?.profile_detail?.id}/profile`
                                      }
                                    >
                                      {item?.contratii_detail[0]?.profile_detail
                                        ?.profile_name
                                        ? item?.contratii_detail[0]
                                            ?.profile_detail?.profile_name
                                        : "NO"}
                                    </Link> */}
                                  </td>

                                  {/* <td>
                                    <Link
                                      to={
                                        item?.player_detail[0]?.players?.name &&
                                        `/panel/view_user/${item?.player_detail[0]?.players?.id}/player`
                                      }
                                    >
                                      {item?.player_detail[0]?.players?.name
                                        ? item?.player_detail[0]?.players?.name
                                        : "NO"}
                                    </Link>
                                  </td> */}

                                  <td>
                                    {item?.collaborter?.length > 0 && (
                                      <TooltipComponent
                                        data={item?.collaborter}
                                      ></TooltipComponent>
                                    )}
                                    {item?.collaborter?.length === 0 && (
                                      <span>NO</span>
                                    )}
                                  </td>

                                  <td>
                                    {item?.groupDetail?.length > 0 && (
                                      <TooltipComponent
                                        data={item?.groupDetail}
                                      ></TooltipComponent>
                                    )}
                                    {item?.groupDetail?.length === 0 && (
                                      <span>NO</span>
                                    )}
                                  </td>

                                  <td>
                                    <span
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                    >
                                      <Link
                                        to={`/panel/update_referent/${item?.id}`}
                                      >
                                        <i class="fa-solid fa-pen"></i>
                                      </Link>
                                    </span>
                                    <span className="toggle-switch">
                                      <Switch
                                        checked={item?.status}
                                        size="small"
                                        color="primary"
                                        onChange={() => {
                                          changeStatus(item?.id, item?.status);
                                        }}
                                        inputProps={{
                                          "aria-label": "toggle switch",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {ReferentsList && ReferentsList.length === 0 && (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                              <td colSpan={9}>
                                <div className="d-flex justify-content-end">
                                  <Link
                                    className="add_circle bg-primary"
                                    to={"/panel/new_referent"}
                                  >
                                    <i class="fa-solid fa-plus text-white"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-4 ml-auto">
                      {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                      <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Add MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body py-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <button
                        type="button"
                        className="close ml-5"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleReset}
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="d-flex justify-content-between ">
                      <h4
                        className="modal-title h3 mb-4 mt-1"
                        id="exampleModalLabel"
                      >
                        Nome Referents
                      </h4>
                      <label htmlFor="fileInput">
                        <img
                          src={selectedImage || IconUser}
                          alt="profile"
                          className="img-sm rounded"
                          style={{ cursor: "pointer" }}
                          onError={(e) => {
                            e.target.src = IconUser;
                          }}
                        />
                      </label>

                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e)}
                      />
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && submit ? "border_error" : ""
                          }`}
                          placeholder="Nome"
                          name="name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.lastname && submit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Cognome"
                          name="lastname"
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-6">
                        <input
                          type="email"
                          className={`form-control ${
                            formik.errors.email && submit ? "border_error" : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-6">
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="IT"
                          value={formik.values.phone}
                          onChange={handlePhoneChange}
                          className={`form-control ${
                            formik.errors.phone && submit ? "border_error" : ""
                          }`}
                          name="phone"
                          disabled={DisabledField}
                        />
                        {/* <input
                          type="number"
                          className={`form-control ${
                            formik.errors.phone && submit ? "border_error" : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Telephone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                          min={0}
                          onWheel={(e)=>e.target.blur()}
                        ></input> */}
                      </div>
                    </div>
                    {!selectedState && (
                      <div className="row mt-3">
                        <div className="col-6">
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.password && submit
                                ? "border_error"
                                : ""
                            }`}
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // disabled={DisabledField}
                          ></input>
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.confirm_password && submit
                                ? "border_error"
                                : ""
                            }`}
                            onBlur={formik.handleBlur}
                            placeholder="Confirm Password"
                            name="confirm_password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            // disabled={DisabledField}
                          ></input>
                        </div>
                      </div>
                    )}
                    <div className="row my-2">
                      <div className="col-6">
                        <textarea
                          className={`form-control ${
                            formik.errors.description && submit
                              ? "border_error"
                              : ""
                          }`}
                          placeholder="Description"
                          name="description"
                          value={formik.values.description}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></textarea>
                      </div>
                      <div className="col-md-6 col-12">
                        <select
                          className="form-control"
                          name="profile_id"
                          onChange={formik.handleChange}
                          value={formik.values?.profile_id}
                          disabled={DisabledField}
                        >
                          <option value={""} selected hidden>
                            Select Profilo
                          </option>
                          {profilolist &&
                            profilolist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.profile_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className=" col-12 mt-2">
                    <div className="row mt-3">
                      <div className="px-2 col-12 col-md-6">
                        {" "}
                        <h5
                          className="modal-title h4 mb-3 mx-1"
                          id="exampleModalLabel"
                        >
                          Gruppo associati
                        </h5>
                        <GroupSearch
                          inputdata={inputdata.current}
                          component={component.current}
                          resetStateRef={resetStateRef.current}
                          onSelectedOptionsChange={handleSelectedOptionsChange}
                        ></GroupSearch>
                      </div>
                      <div className="col-md-6 col-12">
                        <h5
                          className="modal-title h4 mb-3 mx-1"
                          id="exampleModalLabel"
                        >
                          Collaboratori
                        </h5>
                        <CollaboratorsSearch
                          inputdata={inputdataforcollabotors.current}
                          resetStateRef={resetStateRef.current}
                          onSelectedOptionsChange={
                            handleSelectedCollaboratorsOptionsChange
                          }
                        ></CollaboratorsSearch>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    <span
                      className="add_circle bg-danger"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="closeModal"
                      onClick={handleReset}
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </span>
                    {!selectedState && (
                      <span className="add_circle mx-2 bg-success">
                        <button
                          type="submit"
                          className="btn text-white"
                          onClick={() => setsubmit(true)}
                        >
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}
                    {selectedState && DisabledField && (
                      <span
                        className="add_circle mx-2 bg-info"
                        onClick={() => setDisabledField(false)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-pen"></i>
                        </button>
                      </span>
                    )}
                    {selectedState && !DisabledField && (
                      <span
                        className="add_circle mx-2 bg-success"
                        onClick={() => setsubmit(true)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this User ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={() => deleteuser()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
