import { API_URL } from "../services/ApiService";
import { GetService } from "../services/ConstantService";

// utils/formatAmount.js
export const formatAmount = (amount, currency) => {
  if (!amount) return "-";

  const fiatCurrencies = {
    EUR: "de-DE",
    USD: "en-US",
    CHF: "de-CH",
    RON: "ro-RO",
    DOP: "es-DO",
    CNY: "zh-CN",
    BRL: "pt-BR",
    BGN: "bg-BG",
    AED: "ar-AE",
  };
  const cryptoCurrencies = ["ETH", "BTC"];

  const options = { style: "currency", currency: currency };

  if (cryptoCurrencies.includes(currency)) {
    return (
      amount.toFixed(6).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " + currency
    );
  }

  console.log("FROM UTG : ", currency, fiatCurrencies[currency]);
  return new Intl.NumberFormat(fiatCurrencies[currency], options).format(
    amount
  );

  // if (fiatCurrencies.includes(currency)) {
  //   return (
  //     amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " + currency
  //   );
  // }

  // return amount;
};

export const getCurrencyListing = async () => {
  try {
    const res = await GetService(API_URL.GET_CURRENCY);
    if (res?.status) {
      const currencyList = res?.data?.data;

      // Order the currencies
      const orderedCurrencies = ["EUR", "USD", "CHF", "RON", "DOP"];
      const orderedList = currencyList.sort((a, b) => {
        const indexA = orderedCurrencies.indexOf(a);
        const indexB = orderedCurrencies.indexOf(b);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });

      console.log("Currency List: ", orderedList);

      return orderedList;
    }
  } catch (error) {
    console.error("Error fetching currency list:", error);
    return [];
  }
};
