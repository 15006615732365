/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { GetService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import TablePagination from "@mui/material/TablePagination";
import { Swiper, SwiperSlide } from "swiper/react";
import PerformanceCard from "../../Layout/PerformanceCard/PerformanceCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { formatAmount } from "../../../../Helper/utils";
import Chart from "chart.js/auto"; // Ensure Chart.js is imported correctly

const GroupsBalance = () => {
  // State management
  const [groupData, setGroupData] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchReferent, setSearchReferent] = useState("");
  const [dataObj, setDataObj] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  // Table pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setUserLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  // API calls
  const getGroupListing = async () => {
    setLoading(true);
    try {
      const res = await GetService(
        `${
          API_URL.GROUP_Balance_LISTING
        }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
          searchValue
        )}&ref_id=${searchReferent}`
      );
      if (res?.data?.status) {
        setGroupData(res?.data?.data?.list || []);
        setDataObj(res?.data?.data?.totalAmounts || "");
        setTotal(res?.data?.data?.totalItems || 0);
      }
    } catch (error) {
      console.error("Error fetching group listing:", error);
    } finally {
      setLoading(false);
    }
  };

  const getReferentListing = async () => {
    try {
      const res = await GetService(
        API_URL?.REFERENTS_LISTING_WITHOUT_PAGINATION
      );
      if (res?.data?.status) {
        setReferenceList(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching referent listing:", error);
    }
  };

  // Sorting logic
  const handleSort = (columnName) => {
    setSortDirection(
      columnName === sortColumn && sortDirection === "asc" ? "desc" : "asc"
    );
    setSortColumn(columnName);
  };

  const getHeaderClassName = (columnName) => {
    return columnName === sortColumn ? `sorting_${sortDirection}` : "sorting";
  };

  // Memoized sorted data
  const sortedData = useMemo(() => {
    if (!groupData) return [];
    return [...groupData].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      return sortDirection === "asc"
        ? aValue < bValue
          ? -1
          : 1
        : aValue > bValue
        ? -1
        : 1;
    });
  }, [groupData, sortColumn, sortDirection]);

  // Export functionality
  const exportToCSV = () => {
    const exportArray = groupData.map((data) => ({
      Id: data?.id,
      Date: data?.createdAt
        ? format(parseISO(data?.createdAt), "dd/MM/yyyy HH:mm")
        : "NO",
      "A/P": data?.tag,
      "Group Typology": data?.type === "wallet" ? "Wallet" : "Betting Group",
      Status: data?.status === 1 ? "Active" : "Inactive",
      Name: data?.name,
      Referent: data?.referent?.name || "NO",
      "Total-(EUR)": formatAmount(data?.grand_total, "EUR"),
      ...Object.entries(data?.groupcurrency || {}).reduce(
        (acc, [currency, { amount }]) => ({
          ...acc,
          [`(${currency})`]: formatAmount(amount, currency),
        }),
        {}
      ),
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(exportArray[0]).join(",") +
      "\n" +
      exportArray.map((row) => Object.values(row).join(",")).join("\n");

    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Groups_Balance_List.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const calculateTotals = () => {
    const activityTotals = { grand_total: 0 };
    const passivityTotals = { grand_total: 0 };
    const combinedTotals = { grand_total: 0 };

    // Calculate totals for each currency
    sortedData?.forEach((item) => {
      if (item.tag === "Activity") {
        // Sum up currency amounts
        Object.entries(item.groupcurrency || {}).forEach(([currency, data]) => {
          if (currency !== "grand_total") {
            activityTotals[currency] =
              (activityTotals[currency] || 0) + (data.amount || 0);
            combinedTotals[currency] =
              (combinedTotals[currency] || 0) + (data.amount || 0);
          }
        });
        // Add to grand total
        activityTotals.grand_total += item.grand_total || 0;
        combinedTotals.grand_total += item.grand_total || 0;
      } else if (item.tag === "Passivity") {
        // Sum up currency amounts
        Object.entries(item.groupcurrency || {}).forEach(([currency, data]) => {
          if (currency !== "grand_total") {
            passivityTotals[currency] =
              (passivityTotals[currency] || 0) + (data.amount || 0);
            combinedTotals[currency] =
              (combinedTotals[currency] || 0) + (data.amount || 0);
          }
        });
        // Add to grand total
        passivityTotals.grand_total += item.grand_total || 0;
        combinedTotals.grand_total += item.grand_total || 0;
      }
    });

    // Add console log for debugging
    console.log("Activity Totals:", activityTotals);
    console.log("Passivity Totals:", passivityTotals);
    console.log("Combined Totals:", combinedTotals);

    return { activityTotals, passivityTotals, combinedTotals };
  };
  const [totalsByCurrency, setTotalsByCurrency] = useState({});

  const cardCalculateTotals = () => {
    const totalsByCurrency = {};

    sortedData.forEach((item) => {
      if (item.groupcurrency) {
        Object.entries(item.groupcurrency).forEach(([currency, data]) => {
          if (!totalsByCurrency[currency]) {
            totalsByCurrency[currency] = { activityTotal: 0, passivityTotal: 0 };
          }
          if (item.tag === 'Activity') {
            totalsByCurrency[currency].activityTotal += data.amount || 0;
          } else if (item.tag === 'Passivity') {
            totalsByCurrency[currency].passivityTotal += data.amount || 0;
          }
        });
      }
    });
  
    console.log("card cal total : ", totalsByCurrency, sortedData);
    return totalsByCurrency ;
  
  }

  useEffect(() => {
    const totals = cardCalculateTotals();
    setTotalsByCurrency(totals);
  }, [sortedData]);

  const { activityTotals, passivityTotals, combinedTotals } = useMemo(
    () => calculateTotals(),
    [sortedData]
  );

  useEffect(() => {
    getReferentListing();
  }, []);

  useEffect(() => {
    getGroupListing();
  }, [currentPage, searchValue, searchReferent, userLimit]);

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Groups Balance</h3>
              <span>
                <ol className="breadcrumb">
                  <Link to="/panel/dashboard" className="breadcrumb-item">
                    Home
                  </Link>
                  <span className="breadcrumb-item active">Groups Balance</span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <PerformanceCard
            activityData={activityTotals}
            passivityData={passivityTotals}
            combinedData={combinedTotals}
          />
          <hr />
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {/* Search and Export Section */}
                  <div className="row mb-4">
                    <div className="col-12 d-flex">
                      <div className="col-md-4 pl-2">
                        <label htmlFor="search-input" className="search-label">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </label>
                        <input
                          type="search"
                          id="search-input"
                          className="form-control pl-5 form-control-sm"
                          placeholder="Search"
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      </div>

                      <div className="col-2">
                        <select
                          onChange={(e) => setSearchReferent(e.target.value)}
                          className="form-control form-control-sm"
                        >
                          <option value="">Select Referent</option>
                          {referenceList?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-2 ml-auto">
                        <button
                          className="badge badge-outline-primary fa-1x rounded float-right"
                          onClick={exportToCSV}
                        >
                          <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Balance Monitor Section */}
                  <div className="mb-5">
                    <div className="d-flex">
                      <div
                        className="col-2 px-0"
                        style={{ borderRight: "2px solid gray" }}
                      >
                        <h6 className="color fs-16 mb-0">Balance Monitor</h6>
                        <h2 className="my-2 color">
                          <span className="fs-16 text-danger">
                            {formatAmount(dataObj?.grand_total, "EUR")}
                          </span>
                        </h2>
                      </div>
                      <div className="col-10">
                     { <Swiper
      navigation={true}
      slidesPerView={4}
      spaceBetween={20}
      modules={[Navigation]}
      className="mySwiper bgm"
    >
      {Object.entries(totalsByCurrency ?? {}).map(
        ([currency, { activityTotal, passivityTotal }]) =>
          (activityTotal !== 0 || passivityTotal !== 0) && currency !== "grand_total" ? (
            <SwiperSlide
              key={currency}
              className={`groupPersonalCard_${currency} rounded-lg`}
            >
              <div
                className={`card groupPersonalCard groupPersonalCard_${currency} rounded-lg`}
              >
                <div className="card-body py-2">
                  <div className="d-flex justify-content-between">
                    <h6 className="color fs-16 mb-0 font-weight-normal">
                      {currency} Balance
                    </h6>
                  </div>
                  <h2 className="my-2 color text-left">
                    <span className="fs-16">
                      Activity: {formatAmount(activityTotal, currency)}
                    </span>
                  </h2>
                  <h2 className="my-2 color text-left">
                    <span className="fs-16">
                      Passivity: {formatAmount(passivityTotal, currency)}
                    </span>
                  </h2>
                </div>
              </div>
            </SwiperSlide>
          ) : null
      )}
    </Swiper>}
                      </div>
                    </div>
                  </div>

                  {/* Tables Section */}
                  <div className="row px-3 mt-3">
                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      <div className="col-12">
                        <div className="table-responsive-xl">
                          {/* Activity Table */}
                          <h4 className="text-success mb-3">
                            <i className="fa-solid fa-arrow-trend-up mr-2"></i>
                            ACTIVITY
                          </h4>
                          <div className="table-responsive">
                            <table className="table dataTable no-footer table-hover mb-5">
                              <thead>
                                <tr>
                                  <th className="pr-2">S.No</th>
                                  <th className="px-2">Typology</th>
                                  <th
                                    className={getHeaderClassName("status")}
                                    onClick={() => handleSort("status")}
                                  >
                                    Status
                                  </th>
                                  <th
                                    className={`${getHeaderClassName(
                                      "name"
                                    )} px-4`}
                                    onClick={() => handleSort("name")}
                                  >
                                    Name
                                  </th>
                                  <th className="px-4">Referent</th>
                                  <th className="px-3">Total-(EUR)</th>
                                  {Object.entries(dataObj || {}).map(
                                    ([currency, amount]) =>
                                      amount !== 0 &&
                                      currency !== "grand_total" ? (
                                        <th key={currency} className="px-3">
                                          ({currency})
                                        </th>
                                      ) : null
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {sortedData
                                  .filter((item) => item.tag === "Activity")
                                  .map((item, i) => (
                                    <tr key={i}>
                                      <td>{i + currentPage * userLimit + 1}</td>
                                      <td>
                                        {item?.type === "wallet" ? (
                                          <i className="mr-1 fa-solid fa-wallet"></i>
                                        ) : (
                                          <i className="mr-1 fa-solid fa-futbol"></i>
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge badge-${
                                            item?.status === 1
                                              ? "success"
                                              : "danger"
                                          }`}
                                        >
                                          {item?.status ? "Active" : "Inactive"}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          className={`mr-1 ${
                                            item?.tag === "Passivity"
                                              ? "badge-dot-rd"
                                              : "badge-dot-gr"
                                          }`}
                                        ></span>
                                        <Link
                                          to={`/panel/view_group/${item?.id}/personal_data`}
                                        >
                                          {item?.name}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/panel/view_user/${item?.ref_id}/referent`}
                                        >
                                          {item?.referent?.name || "NO"}
                                        </Link>
                                      </td>
                                      <td
                                        className={
                                          item?.grand_total > 0
                                            ? "text-success font-weight-medium"
                                            : item?.grand_total < 0
                                            ? "text-danger font-weight-medium"
                                            : ""
                                        }
                                      >
                                        {formatAmount(item?.grand_total, "EUR")}
                                      </td>
                                      {Object.entries(dataObj || {}).map(
                                        ([currency, amount]) =>
                                          amount !== 0 &&
                                          currency !== "grand_total" ? (
                                            <td
                                              key={currency}
                                              className={
                                                item?.groupcurrency?.[currency]
                                                  ?.amount > 0
                                                  ? "text-success font-weight-medium"
                                                  : item?.groupcurrency?.[
                                                      currency
                                                    ]?.amount < 0
                                                  ? "text-danger font-weight-medium"
                                                  : ""
                                              }
                                            >
                                              {formatAmount(
                                                item?.groupcurrency?.[currency]
                                                  ?.amount,
                                                currency
                                              )}
                                            </td>
                                          ) : null
                                      )}
                                    </tr>
                                  ))}
                                <tr className="bg-light font-weight-bold text-success">
                                  <td colSpan="5" className="text-left">
                                    <i className="fa-solid fa-calculator mr-2"></i>
                                    Activity Total:
                                  </td>
                                  <td className="text-center">
                                    {formatAmount(
                                      sortedData
                                        .filter(
                                          (item) => item.tag === "Activity"
                                        )
                                        .reduce(
                                          (sum, item) =>
                                            sum + (item.grand_total || 0),
                                          0
                                        ),
                                      "EUR"
                                    )}
                                  </td>
                                  {Object.entries(dataObj || {}).map(
                                    ([currency, amount]) =>
                                      amount !== 0 &&
                                      currency !== "grand_total" ? (
                                        <td
                                          key={currency}
                                          className="text-center"
                                        >
                                          {formatAmount(
                                            sortedData
                                              .filter(
                                                (item) =>
                                                  item.tag === "Activity"
                                              )
                                              .reduce(
                                                (sum, item) =>
                                                  sum +
                                                  (item.groupcurrency?.[
                                                    currency
                                                  ]?.amount || 0),
                                                0
                                              ),
                                            currency
                                          )}
                                        </td>
                                      ) : null
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          {/* Passivity Table */}
                          <h4 className="text-danger mb-3 mt-5">
                            <i className="fa-solid fa-arrow-trend-down mr-2"></i>
                            PASSIVITY
                          </h4>
                          <div className="table-responsive">
                            <table className="table dataTable no-footer table-hover mb-5">
                              <thead>
                                <tr>
                                  <th className="pr-2">S.No</th>
                                  <th className="px-2">Typology</th>
                                  <th
                                    className={getHeaderClassName("status")}
                                    onClick={() => handleSort("status")}
                                  >
                                    Status
                                  </th>
                                  <th
                                    className={`${getHeaderClassName(
                                      "name"
                                    )} px-4`}
                                    onClick={() => handleSort("name")}
                                  >
                                    Name
                                  </th>
                                  <th className="px-4">Referent</th>
                                  <th className="px-3">Total-(EUR)</th>
                                  {Object.entries(dataObj || {}).map(
                                    ([currency, amount]) =>
                                      amount !== 0 &&
                                      currency !== "grand_total" ? (
                                        <th key={currency} className="px-3">
                                          ({currency})
                                        </th>
                                      ) : null
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {sortedData
                                  .filter((item) => item.tag === "Passivity")
                                  .map((item, i) => (
                                    <tr key={i}>
                                      <td>{i + currentPage * userLimit + 1}</td>
                                      <td>
                                        {item?.type === "wallet" ? (
                                          <i className="mr-1 fa-solid fa-wallet"></i>
                                        ) : (
                                          <i className="mr-1 fa-solid fa-futbol"></i>
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge badge-${
                                            item?.status === 1
                                              ? "success"
                                              : "danger"
                                          }`}
                                        >
                                          {item?.status ? "Active" : "Inactive"}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          className={`mr-1 ${
                                            item?.tag === "Passivity"
                                              ? "badge-dot-rd"
                                              : "badge-dot-gr"
                                          }`}
                                        ></span>
                                        <Link
                                          to={`/panel/view_group/${item?.id}/personal_data`}
                                        >
                                          {item?.name}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/panel/view_user/${item?.ref_id}/referent`}
                                        >
                                          {item?.referent?.name || "NO"}
                                        </Link>
                                      </td>
                                      <td
                                        className={
                                          item?.grand_total > 0
                                            ? "text-success font-weight-medium"
                                            : item?.grand_total < 0
                                            ? "text-danger font-weight-medium"
                                            : ""
                                        }
                                      >
                                        {formatAmount(item?.grand_total, "EUR")}
                                      </td>
                                      {Object.entries(dataObj || {}).map(
                                        ([currency, amount]) =>
                                          amount !== 0 &&
                                          currency !== "grand_total" ? (
                                            <td
                                              key={currency}
                                              className={
                                                item?.groupcurrency?.[currency]
                                                  ?.amount > 0
                                                  ? "text-success font-weight-medium"
                                                  : item?.groupcurrency?.[
                                                      currency
                                                    ]?.amount < 0
                                                  ? "text-danger font-weight-medium"
                                                  : ""
                                              }
                                            >
                                              {formatAmount(
                                                item?.groupcurrency?.[currency]
                                                  ?.amount,
                                                currency
                                              )}
                                            </td>
                                          ) : null
                                      )}
                                    </tr>
                                  ))}
                                <tr className="bg-light font-weight-bold text-danger">
                                  <td colSpan="5" className="text-left">
                                    <i className="fa-solid fa-calculator mr-2"></i>
                                    Passivity Total:
                                  </td>
                                  <td className="text-center">
                                    {formatAmount(
                                      sortedData
                                        .filter(
                                          (item) => item.tag === "Passivity"
                                        )
                                        .reduce(
                                          (sum, item) =>
                                            sum + (item.grand_total || 0),
                                          0
                                        ),
                                      "EUR"
                                    )}
                                  </td>
                                  {Object.entries(dataObj || {}).map(
                                    ([currency, amount]) =>
                                      amount !== 0 &&
                                      currency !== "grand_total" ? (
                                        <td
                                          key={currency}
                                          className="text-center"
                                        >
                                          {formatAmount(
                                            sortedData
                                              .filter(
                                                (item) =>
                                                  item.tag === "Passivity"
                                              )
                                              .reduce(
                                                (sum, item) =>
                                                  sum +
                                                  (item.groupcurrency?.[
                                                    currency
                                                  ]?.amount || 0),
                                                0
                                              ),
                                            currency
                                          )}
                                        </td>
                                      ) : null
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Pagination */}
                        <div className="mt-4 ml-auto">
                          <TablePagination
                            component="div"
                            count={total}
                            rowsPerPage={userLimit}
                            page={currentPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GroupsBalance;
