/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import "../Group/Group.css";
import { API_URL } from "../../../../services/ApiService";
import {
  DeleteService,
  GetService,
  PostService,
} from "../../../../services/ConstantService";
import { useFormik } from "formik";
import {
  showErrorAlert,
  showtoasterSuccess,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import GroupSearch from "../../Layout/SearchGroup/SearchGroup";
import { useRef } from "react";
import TooltipComponent from "../../Layout/Comman_Tooltip/Tooltip";
import { format, parseISO, set } from "date-fns";
import TablePagination from "@mui/material/TablePagination";
import { getCurrencyListing } from "../../../../Helper/utils";
import { toast } from "react-toastify";

export default function Group() {
  const [groupData, setgroupData] = useState();
  const [formsubmit, setformsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [playerlist, setplayerlist] = useState();
  const [referencelist, setreferencelist] = useState();
  const [profilolist, setprofilolist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const resetStateRef = useRef(false);
  const inputdata = useRef("");
  const [searchValue, setsearchValue] = useState("");
  const [SearchReferent, setSearchReferent] = useState("");
  const [SearchPlayer, setSearchPlayer] = useState("");
  const [SearchProfile, setSearchProfile] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };
  function getgrouplisting() {
    setLoading(true);
    GetService(
      `${
        API_URL.GROUP_LISTING
      }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&ref_id=${SearchReferent}&player_id=${SearchPlayer}&profile_id=${SearchProfile}`
    ).then((res) => {
      setLoading(false);
      console.log(res);
      if (res?.data?.status) {
        setgroupData(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
      }
    });
  }
  // console.log(groupData)
  function getCurrencylisting() {
    getCurrencyListing()
      .then((res) => {
        setcurrencylist(res);
      })
      .catch((err) => {
        console.error("Error fetching currency list:", err);
        toast.error("Error fetching currency list");
      });
  }

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_STATUS_GROUP, data).then(
      (res) => {
        if (res.data.status) {
          showSuccessAlert(res?.data?.message, getgrouplisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };
  const handleResetButtonClick = () => {
    resetStateRef.current = !resetStateRef.current;
  };

  const [GruppoGrandTotal, setGruppoGrandTotal] = useState(0);

  const handleSelectedOptionsChange = (options) => {
    console.log(options);
    setGruppoGrandTotal(0);
    let amount = 0;
    for (let i = 0; i < options.length; i++) {
      const element = options[i];
      amount += Number(element?.grand_total);
      // console.log(amount  ,'--------------')
    }
    // console.log(GruppoGrandTotal)
    setGruppoGrandTotal(amount);
    setSelectedOptions(options);
  };

  useEffect(() => {
    // console.log(GruppoGrandTotal);
    // console.log(GrandTotal);
    // setEURGrandTotal(Number((GrandTotal + GruppoGrandTotal).toFixed(2)));
  }, [GruppoGrandTotal]);

  const formik = useFormik({
    initialValues: {
      type: "wallet",
      name: "",
      currency: "",
      player_id: "",
      profile_id: "",
      amount: "",
      description: "",
      ref_id: "",
      status: 1,
      group: [],
    },
    validate: (values) => {
      const errors = {};

      // Validate 'name' field
      if (!values.name) {
        errors.name = "Name is required";
      }

      // Validate 'profile_id' field
      if (!values.profile_id) {
        errors.profile_id = "profile_id is required";
      }
      // Validate 'player_id' field
      if (!values.player_id) {
        errors.player_id = "player_id is required";
      }

      // Validate 'ref_id' field
      if (!values.ref_id) {
        errors.ref_id = "ref_id is required";
      }
      // Validate 'description' field
      if (!values.description) {
        errors.description = "description is required";
      }
      // Validate 'currency' field
      // if (!AddMoreArray[0]?.currency) {
      //   errors.currency = "currency is required";
      // }
      // // Validate 'amount' field
      // if (!AddMoreArray[0]?.amount) {
      //   errors.amount = "amount is required";
      // }

      return errors;
    },

    onSubmit: (values) => {
      setformsubmit(true);
      if (!formik.isValid) {
        return;
      }
      if (selectedState) {
        values.id = selectedState?.id;
      }
      values.grand_total = GrandTotal;
      values.gruppo_grand_total = GruppoGrandTotal;
      const selectedGroupId = [];
      if (selectedOptions?.length > 0) {
        for (let i of selectedOptions) {
          selectedGroupId.push(i?.id);
        }
        values.group = selectedGroupId;
      } else {
        values.group = selectedGroupId;
      }

      values.groupcurrency = AddMoreArray;
      // if (AddMoreArray?.length > 0) {
      //   values.groupcurrency = AddMoreArray;
      // }
      console.log(values);
      const route = selectedState ? API_URL.UPDATE_GROUP : API_URL.CREATE_GROUP;
      PostService(route, values).then(
        (res) => {
          if (res?.data?.status) {
            showSuccessAlert(res?.data?.message, getgrouplisting);
            document.getElementById("closeModal").click();
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  const handleReset = () => {
    formik.resetForm();
    inputdata.current = "";
    setSelectedOptions("");
    setformsubmit(false);
    handleResetButtonClick();
    setDisabledField(false);
    setAddMoreArray([]);
    // AddMoreArray.length =0
    setAddMoreSubmit(false);
    setGrandTotal(0);
    setEURGrandTotal(0);
    setGruppoGrandTotal(0);
    setAddMoreLoader(false);
  };

  const [DisabledField, setDisabledField] = useState(false);

  function Setdata(values) {
    console.log(values);
    setDisabledField(true);
    formik.setValues({
      type: values?.type,
      name: values?.name,
      // currency: values?.currency,
      player_id: values?.player_id,
      profile_id: values?.profile_id,
      // amount: values?.amount,
      description: values?.description,
      ref_id: values?.ref_id,
      status: values?.status,
    });
    inputdata.current = values?.group_detail;
    setselectedState(values);
    setformsubmit(false);
    if (values.groupcurrency?.length > 0) {
      setAddMoreArray(values.groupcurrency);
    }
    setGrandTotal(Number(values?.grand_total));
    setGruppoGrandTotal(Number(values?.gruppo_grand_total));

    // for (let i = 0; i < values?.group_detail.length; i++) {
    //   const element = values?.group_detail[i];
    //   setGruppoGrandTotal(GruppoGrandTotal + Number(element?.group?.grand_total))
    // }
  }

  useEffect(() => {
    getgrouplisting();
    // getCurrencylisting();
    // getplayerlisting();
    // getreferencelisting();
    // getprofilelisting();
  }, [
    currentPage,
    searchValue,
    SearchReferent,
    SearchProfile,
    SearchPlayer,
    userLimit,
  ]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = groupData?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  // useEffect(()=>{

  // })

  const [AddMoreSubmit, setAddMoreSubmit] = useState(false);
  const [AddMoreLoader, setAddMoreLoader] = useState(false);
  const [AddMoreArray, setAddMoreArray] = useState([]);

  const addfunc = () => {
    if (!formik.values.currency || !formik.values.amount) {
      // console.log("first");
      setAddMoreSubmit(true);
      return;
    }

    if (formik.values.currency !== "EUR") {
      convertInEUR(formik.values.amount, formik.values.currency, "add");
    } else {
      setGrandTotal(
        Number((GrandTotal + Number(formik.values.amount)).toFixed(2))
      );
    }

    AddMoreArray.push({
      amount: formik.values.amount,
      currency: formik.values.currency,
    });
    console.log(AddMoreArray);
    formik.setFieldValue("currency", "");
    formik.setFieldValue("amount", "");
    setAddMoreSubmit(false);
  };

  const removefunc = (data, i) => {
    const newArray = [...AddMoreArray];
    newArray.splice(i, 1);
    setAddMoreArray(newArray);

    if (data?.currency !== "EUR") {
      convertInEUR(data?.amount, data?.currency, "remove");
    } else {
      setGrandTotal(Number((GrandTotal - data?.amount).toFixed(2)));
    }
  };

  const [GrandTotal, setGrandTotal] = useState(0);
  const [EURGrandTotal, setEURGrandTotal] = useState(0);

  const convertInEUR = (amount, currency, type) => {
    setAddMoreLoader(true);
    const obj = { currency: currency, currencyto: "EUR", amount: amount };
    PostService(API_URL.AMOUNT_CONVERSION, obj).then((res) => {
      if (res?.data?.status) {
        const num = res?.data?.data;
        if (type === "add") {
          setGrandTotal(Number((GrandTotal + num).toFixed(2)));
        } else {
          setGrandTotal(Number((GrandTotal - num).toFixed(2)));
        }
      }
      setAddMoreLoader(false);
    });
  };

  useEffect(() => {
    // console.log(GrandTotal);
    // setEURGrandTotal(Number((GrandTotal + GruppoGrandTotal).toFixed(2)));
    setEURGrandTotal(Number(GrandTotal.toFixed(2)));
  }, [GrandTotal]);

  const ConnectedGroups = (item) => {
    console.log(item);
    const groupsArray = [];
    item?.group_detail &&
      item?.group_detail.map((data) => groupsArray.push(data));
    item?.groups_detail &&
      item?.groups_detail.map((data) => groupsArray.push(data));

    return groupsArray;
  };

  const ExportToCSV = () => {
    const ExportArray = [];
    groupData.map((data) => {
      const groupsArray = [];
      data?.group_detail &&
        data?.group_detail.map((data) => groupsArray.push(data));
      data?.groups_detail &&
        data?.groups_detail.map((data) => groupsArray.push(data));

      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd/MM/yyyy HH:mm")
          : "NO",
        "A/P": data?.tag,
        "Group Typology": data?.type === "wallet" ? "Wallet" : "Betting Group",
        Status: data?.status === 1 ? "Active" : "Inactive",
        Name: data?.name,
        Category: data?.category || "NO",
        Referent: data?.referent?.name || "NO",
        Profile: data?.profile?.profile_name || "NO",
        Player: data?.player?.name || "NO",
        Collaborators:
          data?.Collaborators.map(
            (entry, i) => `(${i + 1})${entry?.mainUser?.name}`
          ).join(" ") || "N/O",
        Connected:
          groupsArray
            .map(
              (entry, i) =>
                `(${i + 1})${
                  entry?.satellite_group?.name || entry?.group_main_detail?.name
                }`
            )
            .join(" ") || "N/O",
        Current_Balance:
          data?.groupcurrency
            .map((entry, i) => `(${i + 1})${entry?.amount} ${entry?.currency}`)
            .join(" ") || "N/O",
        Description: data?.description,
      });
    });

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Groups_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  function getreferentlisting() {
    GetService(API_URL?.REFERENTS_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setreferencelist(res?.data?.data);
      }
    });
  }

  const DeleteGroup = () => {
    setLoading(true);
    DeleteService(API_URL.GROUP_DELETE + `/${selectedState?.id}`).then(
      (res) => {
        if (res?.data?.status) {
          showSuccessAlert(res?.data?.message, getgrouplisting);
          document.getElementById("closeDeleteGroupModal").click();
        } else {
          showErrorAlert(res.data?.message);
          document.getElementById("closeDeleteGroupModal").click();
        }
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    getreferentlisting();
    getplayerlisting();
    getprofilelisting();
  }, []);

  function getplayerlisting() {
    GetService(API_URL.PLAYERS_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setplayerlist(res?.data?.data);
      }
    });
  }

  function getprofilelisting() {
    GetService(API_URL.PROFILES_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setprofilolist(res?.data?.data);
      }
    });
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Groups</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <span className="breadcrumb-item active">Groups</span>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <NavLink to={"/panel/new_group"}>
                  <div
                    className="btn  bg-secondprimary rounded-sm text-white"
                    style={{ minWidth: "180px" }}
                  >
                    <i className="fa-solid fa-plus mr-1"></i>
                    Add Group
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex  mb-4">
                    <div className="col-md-4 pl-2">
                      <label htmlFor="search-input" className="search-label">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </label>
                      <input
                        type="search"
                        id="search-input"
                        className="form-control pl-5 form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setsearchValue(e.target.value)}
                      />
                    </div>

                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchReferent(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Referent</option>
                        {referencelist &&
                          referencelist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchProfile(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Profile</option>
                        {profilolist &&
                          profilolist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.profile_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchPlayer(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Player</option>
                        {playerlist &&
                          playerlist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-md-2 ml-auto">
                      <button
                        className="badge badge-outline-primary fa-1x rounded float-right"
                        onClick={ExportToCSV}
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>

                  <div className="row  ">
                    <div class="table-responsive pb-2 px-3">
                      {!loading && (
                        <table class="table  dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th className="pr-2">S.No</th>
                              {/* <th
                                className={getHeaderClassName("id")}
                                onClick={() => handleSort("id")}
                              >
                                ID
                              </th> */}
                              <th className="pr-2">A/P</th>
                              <th className="pl-4">T</th>
                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>

                              <th
                                className={`${getHeaderClassName("name")} px-4`}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </th>
                              <th
                                className="px-3"
                                // className={`${getHeaderClassName(
                                //   "user"
                                // )} text-left`}
                                // onClick={() => handleSort("user")}
                              >
                                Referent
                              </th>
                              <th
                                className="px-3"
                                // className={`${getHeaderClassName(
                                //   "profile"
                                // )} text-left`}
                                // onClick={() => handleSort("profile")}
                              >
                                Profile
                              </th>
                              <th
                                className="px-3"
                                // className={`${getHeaderClassName(
                                //   "player"
                                // )} text-left`}
                                // onClick={() => handleSort("player")}
                              >
                                Player
                              </th>

                              <th
                                className="px-0"
                                // className={getHeaderClassName("group_detail")}
                                // onClick={() => handleSort("group_detail")}
                              >
                                Connected
                              </th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groupData &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td className="">
                                    <span className="">
                                      {i + currentPage * userLimit + 1}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        item?.tag === "Activity"
                                          ? "badge bg-inverse-success text-black"
                                          : item?.tag === "Passivity"
                                          ? "badge bg-inverse-danger text-black"
                                          : ""
                                      }
                                    >
                                      {item?.tag}
                                    </span>
                                  </td>

                                  {/* <td className="text-left">{item?.id}</td> */}
                                  <td className=" ">
                                    {item?.type === "wallet" ? (
                                      <i className="mr-1 fa-solid fa-wallet"></i>
                                    ) : (
                                      // <span
                                      //   style={{
                                      //     backgroundColor: "rgb(124 32 171)",
                                      //     color: "#fff",
                                      //     borderRadius: "12px",
                                      //     padding: "0px 4px",
                                      //   }}
                                      // >
                                      //   w
                                      // </span>
                                      <i className="mr-1 fa-solid fa-futbol"></i>
                                      // <span
                                      //   style={{
                                      //     backgroundColor: "#11bad5",
                                      //     color: "#fff",
                                      //     borderRadius: "10px",
                                      //     padding: "1px 5px",
                                      //     fontSize: "small",
                                      //   }}
                                      // >
                                      //   G
                                      // </span>
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_group/${item?.id}/personal_data`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_user/${item?.ref_id}/referent`}
                                    >
                                      {item?.referent
                                        ? item?.referent?.name
                                        : "NO"}
                                    </Link>
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_user/${item?.profile_id}/profile`}
                                    >
                                      {item?.profile
                                        ? item?.profile?.profile_name
                                        : "NO"}
                                    </Link>
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_user/${item?.player_id}/player`}
                                    >
                                      {item?.player ? item?.player?.name : "NO"}
                                    </Link>
                                  </td>
                                  <td>
                                    {(item?.group_detail?.length > 0 ||
                                      item?.groups_detail?.length > 0) && (
                                      <TooltipComponent
                                        data={ConnectedGroups(item)}
                                        // data={item?.group_detail}
                                      ></TooltipComponent>
                                    )}
                                    {item?.group_detail?.length === 0 &&
                                      item?.groups_detail?.length === 0 && (
                                        <span>NO</span>
                                      )}
                                  </td>

                                  <td>
                                    <span
                                      className="mx-2"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Link
                                        to={`/panel/view_group/${item?.id}/transaction`}
                                      >
                                        <i
                                          class="fa fa-exchange"
                                          aria-hidden="true"
                                          title="Transaction"
                                        ></i>
                                      </Link>
                                    </span>
                                    <span
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                    >
                                      <Link
                                        to={`/panel/update_group/${item?.id}`}
                                      >
                                        <i class="fa-solid fa-pen"></i>
                                      </Link>
                                    </span>
                                    {/* <span
                                      data-toggle="modal"
                                      data-target="#AddModal"
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                      onClick={() => Setdata(item)}
                                    >
                                      <i class="fa-solid fa-pen"></i>
                                    </span> */}
                                    <span className="toggle-switch">
                                      <Switch
                                        checked={item?.status}
                                        size="small"
                                        color="primary"
                                        onChange={() => {
                                          changeStatus(item?.id, item?.status);
                                        }}
                                        inputProps={{
                                          "aria-label": "toggle switch",
                                        }}
                                      />
                                    </span>

                                    <span
                                      data-toggle="modal"
                                      data-target="#DeleteGroupModal"
                                      className="mx-2"
                                      onClick={() => setselectedState(item)}
                                    >
                                      <i
                                        class="fa fa-trash text-danger cursor"
                                        aria-hidden="true"
                                        title="Delete"
                                      ></i>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {groupData?.length === 0 && (
                              <tr>
                                <td colSpan="11" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            <tr>
                              {/* <td colSpan={11}>
                                <div className="d-flex justify-content-end">
                                  <div
                                    className="add_circle bg-primary"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    onClick={() => {
                                      handleReset();
                                      setselectedState("");
                                    }}
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </div>
                                </div>
                              </td> */}
                              {/* <td colSpan={11}>
                                <div className="d-flex justify-content-end">
                                  <Link className="add_circle bg-primary" to={'/panel/new_group'}>
                                    <i class="fa-solid fa-plus text-white"></i>
                                  </Link>
                                </div>
                              </td> */}
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>

                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-4 ml-auto">
                      {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                      <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Add MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body py-4">
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <h4 className="modal-title h3 mb-3" id="exampleModalLabel">
                      Nome Gruppo
                    </h4>
                    <div className="row mt-4">
                      <div className="col-6 d-flex">
                        <input
                          className="form-control col-2"
                          type="radio"
                          name="type"
                          id="wallet"
                          checked={formik.values.type === "wallet"}
                          value="wallet"
                          onChange={formik.handleChange}
                        />{" "}
                        <label htmlFor="wallet">
                          <i class="fa-solid fa-wallet"></i> Wallet
                        </label>
                      </div>
                      <div className="col-6 d-flex">
                        <input
                          className="form-control col-2"
                          type="radio"
                          name="type"
                          id="generic"
                          checked={formik.values.type === "generic"}
                          value="generic"
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="generic">
                          <i class="fa-solid fa-futbol"></i> Gruppo Generico
                        </label>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Nome Gruppo"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-6">
                        <select
                          onChange={formik.handleChange}
                          value={formik.values.ref_id}
                          name="ref_id"
                          className={`form-control ${
                            formik.errors.ref_id && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          disabled={DisabledField}
                        >
                          <option value={""} selected hidden>
                            Select Referenti
                          </option>
                          {referencelist &&
                            referencelist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.name + " " + item?.lastname}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <select
                          className={`form-control ${
                            formik.errors.profile_id && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          name="profile_id"
                          onChange={formik.handleChange}
                          value={formik.values.profile_id}
                          disabled={DisabledField}
                        >
                          <option value={""} selected hidden>
                            Select Profilo
                          </option>
                          {profilolist &&
                            profilolist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.profile_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          className={`form-control ${
                            formik.errors.player_id && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="player_id"
                          value={formik.values.player_id}
                          disabled={DisabledField}
                        >
                          <option value={""} selected hidden>
                            Select Player
                          </option>
                          {playerlist &&
                            playerlist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.player_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <textarea
                          placeholder="Descrizione"
                          name="description"
                          className={`form-control ${
                            formik.errors.description && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></textarea>
                      </div>
                    </div>{" "}
                    <hr />
                    <div className="row mt-3">
                      <div className="col-1 p-0 ml-3">
                        <span
                          className="add_circle bg-primary"
                          type="button"
                          onClick={() => addfunc()}
                        >
                          <i class="fa-solid fa-plus"></i>
                        </span>
                      </div>
                      <div className="col-6">
                        <input
                          type="number"
                          className={`form-control ${
                            formik.values.amount === "" && AddMoreSubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Saldo Iniziale"
                          name="amount"
                          value={formik.values.amount}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                          min={0}
                          onWheel={(e) => e.target.blur()}
                        ></input>
                      </div>
                      <div className="col-3">
                        <select
                          className={`form-control ${
                            formik.values.currency === "" && AddMoreSubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          name="currency"
                          onChange={formik.handleChange}
                          value={formik.values.currency}
                          disabled={DisabledField}
                        >
                          <option value={""} selected hidden>
                            Currency
                          </option>
                          {currencylist &&
                            currencylist?.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {AddMoreArray && (
                      <>
                        <div className="mt-3">
                          {AddMoreArray.map((data, i) => (
                            <div key={i} className="row mt-2">
                              <div className="col-1 p-0 ml-3">
                                <span
                                  className="add_circle bg-primary"
                                  type="button"
                                  onClick={() => removefunc(data, i)}
                                >
                                  <i class="fa-solid fa-minus"></i>
                                </span>
                              </div>
                              <div className="col-6">
                                <input
                                  type="number"
                                  className={`form-control`}
                                  placeholder="Saldo Iniziale"
                                  name="amount"
                                  value={data?.amount}
                                  disabled
                                ></input>
                              </div>
                              <div className="col-3">
                                <select
                                  className={`form-control `}
                                  name="currency"
                                  value={data?.currency}
                                  disabled
                                >
                                  <option value="">{data?.currency}</option>
                                </select>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="mt-3">
                          <div className="d-flex">
                            <label className="h5 mt-3">
                              Grand Total (EUR) :
                            </label>
                            &nbsp;
                            <input
                              type="text"
                              value={EURGrandTotal}
                              className="form-control col-4"
                              readOnly
                            />
                            {AddMoreLoader && (
                              <div
                                class="spinner-border spinner-border-sm mt-3"
                                role="status"
                              ></div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                    <div className="d-flex justify-content-end mb-3">
                      <button
                        type="button"
                        className="close "
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleReset}
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div>
                      <h5
                        className="modal-title h4 mb-3 mx-2"
                        id="exampleModalLabel"
                      >
                        Da allacciare a: (Satellite)
                      </h5>
                    </div>
                    <div
                      className="px-2"
                      style={{ borderLeft: "1px solid #000" }}
                    >
                      <GroupSearch
                        resetStateRef={resetStateRef.current}
                        inputdata={inputdata.current}
                        onSelectedOptionsChange={handleSelectedOptionsChange}
                      ></GroupSearch>
                      <div className="mt-4 d-flex">
                        <input
                          type="checkbox"
                          name="status"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                        />
                        <label className="h5 ml-2">Attiva Satellite</label>
                      </div>

                      {/* <div className=" float-right d-flex">
                        <span
                          className="add_circle bg-danger"
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          id="closeModal"
                          onClick={handleReset}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </span>
                        {!selectedState && (
                          <span
                            className="add_circle mx-2 bg-success"
                            onClick={() => setformsubmit(true)}
                          >
                            <button type="submit" className="btn text-white">
                              <i class="fa-solid fa-check"></i>
                            </button>
                          </span>
                        )}

                        {selectedState && DisabledField && (
                          <span
                            className="add_circle mx-2 bg-info"
                            onClick={() => setDisabledField(false)}
                          >
                            <button type="submit" className="btn text-white">
                              <i class="fa-solid fa-pen"></i>
                            </button>
                          </span>
                        )}
                        {selectedState && !DisabledField && (
                          <span
                            className="add_circle mx-2 bg-success"
                            onClick={() => setformsubmit(true)}
                          >
                            <button type="submit" className="btn text-white">
                              <i class="fa-solid fa-check"></i>
                            </button>
                          </span>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <div className=" float-right d-flex">
                  <span
                    className="add_circle bg-danger"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                    onClick={handleReset}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </span>
                  {!selectedState && (
                    <span
                      className="add_circle mx-2 bg-success"
                      onClick={() => {
                        setformsubmit(true);
                        console.log("ddfddd", formsubmit);
                      }}
                    >
                      <button type="submit" className="btn text-white">
                        <i class="fa-solid fa-check"></i>
                      </button>
                    </span>
                  )}

                  {selectedState && DisabledField && (
                    <span
                      className="add_circle mx-2 bg-info"
                      onClick={() => setDisabledField(false)}
                    >
                      <button type="submit" className="btn text-white">
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </span>
                  )}
                  {selectedState && !DisabledField && (
                    <span
                      className="add_circle mx-2 bg-success"
                      onClick={() => setformsubmit(true)}
                    >
                      <button type="submit" className="btn text-white">
                        <i class="fa-solid fa-check"></i>
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this User ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={() => deleteuser()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteGroupModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Group !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this Group ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="closeDeleteGroupModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={DeleteGroup}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
