/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, NavLink, json } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../../services/ConstantService";
import { useFormik } from "formik";
import IconUser from "../../../../assets/img/IconUser.png";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import { useRef } from "react";
import TooltipComponent from "../../Layout/Comman_Tooltip/Tooltip";
import ReferentSearch from "./Referents_search";
import { ImageURL } from "../../../../Environment/Environment";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { format, parseISO } from "date-fns";
import TablePagination from "@mui/material/TablePagination";

export default function Collaborators() {
  const [CollaboratorsList, setCollaboratorsList] = useState();
  const [totalPages, setTotalPages] = useState();
  const [submit, setsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [grouplist, setgrouplist] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const [userLimit, setUserLimit] = useState(10);
  const [searchValue, setsearchValue] = useState("");
  const [SearchReferent, setSearchReferent] = useState("");
  const [referencelist, setreferencelist] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const resetStateRef = useRef(false);
  const inputdata = useRef("");
  const component = useRef("collaborator");

  const handleReset = () => {
    formik.resetForm();
    inputdata.current = "";
    setsubmit(false);
    setSelectedOptions("");
    handleResetButtonClick();
    setDisabledField(false);
    setselectedFile("");
    setSelectedImage("");
  };
  const handleSelectedOptionsChange = (options) => {
    setSelectedOptions(options);
  };
  const handleResetButtonClick = () => {
    resetStateRef.current = !resetStateRef.current;
  };

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_STATUS_COLLABORATORS, data).then(
      (res) => {
        if (res.data.status) {
          showSuccessAlert(res?.data?.message, getCollaboratorslisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };

  function getCollaboratorslisting() {
    setLoading(true);
    GetService(
      `${
        API_URL.COLLABORATORS_LISTING
      }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&ref_id=${SearchReferent}`
    ).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        setCollaboratorsList(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
      }
    });
  }
  function getgrouplisting() {
    GetService(API_URL.GROUP_LISTING).then((res) => {
      if (res?.data?.status) {
        setgrouplist(res?.data?.data?.list);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      phone: "",
      status: 1,
      password: "",
      confirmPassword: "",
      description: "",
    },
    validate: (values) => {
      const errors = {};

      // Validate 'name' field
      if (!values.name) {
        errors.name = "Name is required";
      }

      // Validate 'lastname' field
      if (!values.lastname) {
        errors.lastname = "Lastname is required";
      }

      // Validate 'email' field
      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      // Validate 'phone' field
      if (!values.phone) {
        errors.phone = "Phone is required";
      } else if (CheckTelLength(values.phone)) {
        errors.phone = "Phone length";
      }

      if (!selectedState && !values.password) {
        errors.password = "Password is required";
      }

      if (!selectedState) {
        if (!values.confirmPassword) {
          errors.confirmPassword = "Confirm Password is required";
        } else if (values.confirmPassword !== values.password) {
          errors.confirmPassword = "Password mismatch";
        }
      }
      if (!values.description) {
        errors.description = "description is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      if (formik.isValid) {
        const route = selectedState
          ? API_URL.UPDATE_COLLABORATORS
          : API_URL.CREATE_COLLABORATORS;
        // if (selectedState) {
        //   values.id = selectedState?.id;
        // }
        // if (values?.lastname && values?.name) {
        //   values.user_name = values?.name + " " + values?.lastname;
        // }
        const selectedrefrentsId = [];
        if (selectedOptions?.length > 0) {
          for (let i of selectedOptions) {
            selectedrefrentsId.push(i?.id);
          }
          values.refrents = selectedrefrentsId;
        }

        const Formdata = new FormData();
        if (selectedState) {
          Formdata.append("id", selectedState?.id);
        }
        if (values?.lastname && values?.name) {
          Formdata.append("user_name", values?.name + " " + values?.lastname);
        }
        Formdata.append("email", values?.email);
        Formdata.append("lastname", values?.lastname);
        Formdata.append("name", values?.name);
        Formdata.append("phone", values?.phone);
        Formdata.append("profile_image", selectedFile);
        Formdata.append("refrents", JSON.stringify(selectedrefrentsId));
        Formdata.append("status", 1);
        if (!selectedState) {
          Formdata.append("password", values?.password);
          Formdata.append("confirm_password", values?.confirmPassword);
        }
        Formdata.append("description", values?.description);

        PostFormData(route, Formdata).then(
          (res) => {
            if (res?.data.status) {
              showSuccessAlert(res?.data?.message, getCollaboratorslisting);
              document.getElementById("closeModal").click();
            } else {
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            showErrorAlert(err.data?.message);
          }
        );
      }
    },
  });

  const [DisabledField, setDisabledField] = useState(false);

  function Setdata(values) {
    setDisabledField(true);
    setSelectedImage(ImageURL + values?.profile_image);
    setselectedFile(values?.profile_image);
    formik.setValues({
      name: values?.name,
      lastname: values?.lastname,
      phone: values?.phone,
      email: values?.email,
      status: values?.status,
      description: values?.description,
    });
    inputdata.current = values?.refrents_detail;
    setselectedState(values);
    component.current = "collaborator";
  }

  useEffect(() => {
    getCollaboratorslisting();
    // getgrouplisting();
  }, [currentPage, searchValue,SearchReferent,userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = CollaboratorsList?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const [selectedFile, setselectedFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));

    console.log("Selected file:", e.target.files[0]);
  };

  const handlePhoneChange = (value) => {
    console.log(value);
    if (value && value.trim() !== "") {
      formik.setFieldValue("phone", value);
    }
  };

  function CheckTelLength(value) {
    // console.log(value)
    const phoneNumberObject = parsePhoneNumber(value);
    const nsn = phoneNumberObject ? phoneNumberObject.nationalNumber : "";
    const nsnLength = nsn.length;
    // console.log(nsn)
    // console.log(nsnLength)

    if (nsnLength >= 8 && nsnLength <= 15) {
      return false;
    } else {
      return true;
    }
  }

  const ExportToCSV = () => {
    const ExportArray = [];
    CollaboratorsList.map((data) =>
      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd/MM/yyyy HH:mm")
          : "NO",
        Status: data?.status === 1 ? "Active" : "Inactive",

        Name: data?.name,
        Username: data?.username,
        Email: data?.email,
        Telephone: data?.phone,
        "Connected Referent": data?.refrents_detail.map(
          (entry) => `${entry?.refrents?.name}`
        ),
        Description: data?.description,
      })
    );

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Collaborators_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  function getreferentlisting() {
    GetService(API_URL?.REFERENTS_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setreferencelist(res?.data?.data);
      }
    });
  }

  useEffect(()=>{
    getreferentlisting() 
  },[])

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Collaborators</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">Collaborators</Link>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <NavLink to={"/panel/new_collaborator"}>
                  <div
                    className="btn  bg-secondprimary rounded-sm text-white"
                    style={{ minWidth: "180px" }}
                  >
                    <i className="fa-solid fa-plus mr-1"></i>
                    Add Collaborator
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mb-4">
                    <div className="col-md-4 pl-2">
                      <label htmlFor="search-input" className="search-label">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </label>
                      <input
                        type="search"
                        id="search-input"
                        className="form-control pl-5 form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setsearchValue(e.target.value)}
                      />
                    </div>

                    <div className="col-2">
                        <select
                          onChange={(e)=>setSearchReferent(e.target.value)} 
                          className={`form-control form-control-sm`} 
                        >
                          <option value={""}>
                            Select Referent
                          </option>
                          {referencelist &&
                            referencelist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>

                    <div className="col-md-2 ml-auto">
                      <button
                        className="badge badge-outline-primary fa-1x rounded float-right"
                        onClick={ExportToCSV}
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="row px-3">
                    <div class="table-responsive mt-4 ">
                      {!loading && (
                        <table class="table dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              {/* <th
                                className={getHeaderClassName("id")}
                                onClick={() => handleSort("id")}
                              >
                                ID
                              </th> */}
                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>
                              <th
                                className={getHeaderClassName("name")}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </th>
                              <th
                              // className={getHeaderClassName("email")}
                              // onClick={() => handleSort("email")}
                              >
                                Email
                              </th>
                              <th>Referent</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CollaboratorsList &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td> {i + currentPage * userLimit + 1}</td>
                                  {/* <td>{item?.id}</td> */}
                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/panel/view_user/${item?.id}/collaborator`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </td>
                                  <td>{item?.email ? item?.email : "NO"}</td>
                                  {/* <td>
                                    {item?.refrents_detail?.length > 0 && (
                                      <TooltipComponent
                                        data={item?.refrents_detail}
                                      ></TooltipComponent>
                                    )}
                                    {item?.refrents_detail?.length === 0 && (
                                      <span>NO</span>
                                    )}
                                  </td> */}
                                  <td>
                                    <Link
                                      to={`/panel/view_user/${item?.refrents_detail[0]?.refrents?.id}/referent`}
                                    >
                                      {item?.refrents_detail[0]?.refrents
                                        ?.name || "NO"}
                                    </Link>
                                  </td>
                                  <td>
                                    <span
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                    >
                                      <Link
                                        to={`/panel/update_collaborator/${item?.id}`}
                                      >
                                        <i class="fa-solid fa-pen"></i>
                                      </Link>
                                    </span>
                                    <span className="toggle-switch">
                                      <Switch
                                        checked={item?.status}
                                        size="small"
                                        color="primary"
                                        onChange={() => {
                                          changeStatus(item?.id, item?.status);
                                        }}
                                        inputProps={{
                                          "aria-label": "toggle switch",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {CollaboratorsList &&
                              CollaboratorsList.length === 0 && (
                                <tr>
                                  <td colSpan="7" className="text-center">
                                    <h6 className="p-3"> No Data Found </h6>
                                  </td>
                                </tr>
                              )}
                            {/* <tr>
                              <td colSpan={7}>
                                <div className="d-flex justify-content-end">
                                <Link className="add_circle bg-primary" to={'/panel/new_collaborator'}>
                                    <i class="fa-solid fa-plus text-white"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}
</div>
                      {loading ? (
                        <div className="LoaderSpinner">
                          <MoonLoader
                            color={"#003bff"}
                            loading={loading}
                            speedMultiplier={0.6}
                            size={50}
                          />
                          <div>Loading... Please wait.</div>
                        </div>
                      ) : (
                        ""
                      )}

<div className="mt-4 ml-auto">
                        {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                         <TablePagination
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Add MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body py-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <div className="d-flex justify-content-between">
                      <h4
                        className="modal-title h3 mb-4 mt-1"
                        id="exampleModalLabel"
                      >
                        Nome collaborator
                      </h4>
                      <label htmlFor="fileInput">
                        <img
                          src={selectedImage || IconUser}
                          alt="profile"
                          className="img-sm rounded"
                          style={{ cursor: "pointer" }}
                          onError={(e) => {
                            e.target.src = IconUser;
                          }}
                        />
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e)}
                      />
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && submit ? "border_error" : ""
                          }`}
                          placeholder="Nome"
                          name="name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.lastname && submit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Cognome"
                          name="lastname"
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-6">
                        <input
                          type="email"
                          className={`form-control ${
                            formik.errors.email && submit ? "border_error" : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-6">
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="IT"
                          value={formik.values.phone}
                          onChange={handlePhoneChange}
                          className={`form-control ${
                            formik.errors.phone && submit ? "border_error" : ""
                          }`}
                          name="phone"
                          disabled={DisabledField}
                        />
                        {/* <input
                          type="number"
                          className={`form-control ${
                            formik.errors.phone && submit ? "border_error" : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Telefono"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                          min={0}
                          onWheel={(e)=>e.target.blur()}
                        ></input> */}
                      </div>
                    </div>

                    {!selectedState && (
                      <div className="row mt-3">
                        <div className="col-6">
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.password && submit
                                ? "border_error"
                                : ""
                            }`}
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // disabled={DisabledField}
                          ></input>
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.confirmPassword && submit
                                ? "border_error"
                                : ""
                            }`}
                            onBlur={formik.handleBlur}
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            // disabled={DisabledField}
                          ></input>
                        </div>
                      </div>
                    )}

                    <div className="row mt-3">
                      <div className="col-12">
                        <textarea
                          className={`form-control ${
                            formik.errors.description && submit
                              ? "border_error"
                              : ""
                          }`}
                          placeholder="Description"
                          name="description"
                          value={formik.values.description}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                    <div className="row mb-3">
                      <div className="align-items-center col-12 d-flex justify-content-between">
                        <h5
                          className="modal-title h4  mx-2"
                          id="exampleModalLabel"
                        >
                          Referents
                        </h5>
                        <button
                          type="button"
                          className="close px-4"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={handleReset}
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>

                    <div
                      className="px-2"
                      style={{ borderLeft: "1px solid #000" }}
                    >
                      <ReferentSearch
                        inputdata={inputdata.current}
                        component={component.current}
                        resetStateRef={resetStateRef.current}
                        onSelectedOptionsChange={handleSelectedOptionsChange}
                      ></ReferentSearch>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    <span
                      className="add_circle bg-danger"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="closeModal"
                      onClick={handleReset}
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </span>
                    {!selectedState && (
                      <span className="add_circle mx-2 bg-success">
                        <button
                          type="submit"
                          className="btn text-white"
                          onClick={() => setsubmit(true)}
                        >
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}

                    {selectedState && DisabledField && (
                      <span
                        className="add_circle mx-2 bg-info"
                        onClick={() => setDisabledField(false)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-pen"></i>
                        </button>
                      </span>
                    )}
                    {selectedState && !DisabledField && (
                      <span
                        className="add_circle mx-2 bg-success"
                        onClick={() => setsubmit(true)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this User ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={() => deleteuser()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
