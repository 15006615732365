/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { GetService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";

const GroupSearch = ({
  onSelectedOptionsChange,
  resetStateRef,
  inputdata,
  component,
  height,
  existingGroupId,
}) => {
  const [search, setSearch] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [hideDropDown, setHideDropDown] = useState(true);
  const [groupDataWithoutPagination, setGroupDataWithoutPagination] = useState(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isInputClicked, setIsInputClicked] = useState(false); // New state for input click tracking

  const getGroupListingWithoutPagination = () => {
    const url =
      search?.length > 0
        ? `${API_URL.GROUP_LISTING_WITHOUT_PAGINATION}?searchKey=${search}`
        : `${API_URL.GROUP_LISTING_WITHOUT_PAGINATION}`;
    GetService(url).then((res) => {
      if (res?.status) {
        if (existingGroupId) {
          res.data.data = res.data.data.filter(
            (group) => +group.id !== +existingGroupId
          );
        }

        const filteredData = res.data.data.filter(
          (group) => !selectedOptions.some((option) => option.id === group.id)
        );

        console.log("<E> : ", existingGroupId, res.data.data);

        setGroupDataWithoutPagination(filteredData);
      }
    });
  }


  const handleResetState = () => {
    setSearch("");
    setSelectedSearch("");
    setHideDropDown(true);
    setGroupDataWithoutPagination([]);
    setSelectedOptions([]);
    setIsInputClicked(false); // Reset input click state

    onSelectedOptionsChange([]);
  };
  // const handleforEdit = () => {
  //   const data = inputdata;
  //   console.log(inputdata);
  //   for (let i = 0; i < data?.length; i++) {
  //     // const setdata = component === "player" ? data[i]?.group : data[i]?.group;
  //     const setdata = (component === "player" || component === "referent") ? data[i] : data[i]?.satellite_group : data[i]?.group_main_detail : '';
  //     // const setdata = data[i]?.satellite_group;
  //     console.log(data);
  //     console.log(setdata);
  //     handleOptionSelect(setdata);
  //   }
  // };

  const handleforEdit = () => {
    const data = inputdata;
    console.log(inputdata);
    for (let i = 0; i < data?.length; i++) {
      let setdata;
      if (component === "player" || component === "referent") {
        setdata = data[i];
      } else {
        setdata = data[i]?.satellite_group
          ? data[i].satellite_group
          : data[i]?.group_main_detail
          ? data[i].group_main_detail
          : "";
      }
      console.log(data);
      console.log(setdata);
      handleOptionSelect(setdata);
    }
  };

  useEffect(() => {
    handleResetState();
  }, [resetStateRef]);

  useEffect(() => {
    console.log(inputdata);
    console.log(component);
    handleforEdit();
  }, [inputdata]);

  useEffect(() => {
    getGroupListingWithoutPagination();
  }, [search]);

  const handleOptionSelect = (option) => {
    if (option?.id) {
      setSelectedOptions((prevOptions) => {
        const isOptionExists = prevOptions.some(
          (prevOption) => prevOption.id === option.id
        );

        if (!isOptionExists) {
          const newOptions = [...prevOptions, option];
          onSelectedOptionsChange(newOptions);
          setSelectedSearch("");
          return newOptions;
        } else {
          return prevOptions;
        }
      });
    }
  };

  const handleRemoveOption = (index) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      onSelectedOptionsChange(updatedOptions);
      setSelectedSearch("");
      return updatedOptions;
    });
  };

  return (
    <div
      className="card card-bg py-2"
      style={{ height: height ? height : "200px" }}
    >
      {component !== "player" && component !== "referent" && (
        <div className="mt-2 mx-2 row align-items-center">
          <div className="col-10 px-0 side_search">
            <input
              type="text"
              className="form-control"
              name="group"
              onClick={() => {
                setIsInputClicked(true); // Set input clicked state
                getGroupListingWithoutPagination(); // Fetch data on click
              }}
              onChange={(e) => {
                setSearch(e.target.value);
                setHideDropDown(true); // Show dropdown on input change
              }}
              value={search}
              style={{ paddingLeft: "30px" }}
              placeholder={
                component === "collaborator"
                  ? "Search Referents"
                  : "Add Group or Wallet"
              }
              autoComplete="off"
            />
            <div
              className="bg-white position-absolute rounded overflow-auto"
              style={{
                minHeight: "50px",
                maxHeight: "150px",
                zIndex: "1",
                width: "100%",
                top: "103%",
                display: isInputClicked && hideDropDown ? "block" : "none", // Show dropdown only if input is clicked
              }}
            >
              <div className="align-items-center d-flex flex-column justify-content-center py-2 drop">
                <ul className="px-3 w-100 list-inline">
                  <p className="text-small text-center">
                    {groupDataWithoutPagination?.length !== 0
                      ? ""
                      : "No Result Found"}
                  </p>
                  {groupDataWithoutPagination &&
                    groupDataWithoutPagination?.map((item, i) => (
                      <li
                        key={i}
                        className="px-2 py-1 text-small rounded"
                        onClick={() => {
                          setSelectedSearch(item);
                          setSearch(item?.name);
                          setHideDropDown(false);
                        }}
                      >
                        {item?.type === "wallet" ? (
                          <i className="fa-solid fa-wallet"></i>
                        ) : (
                          <i className="fa-solid fa-futbol"></i>
                        )}{" "}
                        &nbsp; {item?.name}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div
              className="position-absolute"
              style={{ top: "6px ", left: "10px" }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </div>
          </div>
          <div className="col-2 px-1">
            <div
              className="add_circle mx-2 icon_size bg-primary"
              onClick={() => {
                handleOptionSelect(selectedSearch);
                setSearch("");
              }}
            >
              <i className="fa-solid fa-plus"></i>
            </div>
          </div>
        </div>
      )}

      <div className="mr-1 text-center mt-1 overflow-auto">
        {selectedOptions?.length === 0 && (
          <p className="h5 my-3 fs_12">
            {component === "collaborator"
              ? "No Referents Added"
              : "No Gruppo Added"}{" "}
          </p>
        )}
        {selectedOptions.map((option, i) => (
          <div
            key={i}
            className="align-items-center my-2 badge-secondary d-flex justify-content-between mx-2 px-3 py-0 pt-1 mt-1"
          >
            <p>
              {option?.type === "wallet" ? (
                <i className="fa-solid fa-wallet"></i>
              ) : (
                <i className="fa-solid fa-futbol"></i>
              )}{" "}
              &nbsp; {option?.name}
            </p>
            {component !== "player" && component !== "referent" && (
              <i
                className="fa fa-minus-circle cursor text-danger"
                onClick={() => handleRemoveOption(i)}
              ></i>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupSearch;
